import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import { List, ListItem, IconButton, Stack } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const MyDropzone = ({ title, styleTitle }) => {
  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };
  const files = myFiles.map((file) => (
    <ListItem key={file.path} sx={{ color: "#FEDA3B" }}>
      <IconButton onClick={removeFile(file)} aria-label="delete" color="error">
        <RemoveCircleIcon />
      </IconButton>
      {file.path}
    </ListItem>
  ));

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <span style={styleTitle}>{title}</span>
      </div>

      <List>
        <Stack>{files}</Stack>
      </List>
    </>
  );
};

export default MyDropzone;
