import React, { useState } from "react";
import { Stack, Avatar, Typography, Paper, IconButton } from "@mui/material";

import {
  Card,
  TableHead,
  TableRow,
  TableCell,
  Button,
  InputBase,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";

import {
  DatagridBody,
  Datagrid,
  FunctionField,
  TextField,
  RecordContextProvider,
  useRedirect,
  ListBase,
} from "react-admin";

const activeStyle = {
  backgroundColor: "#FEDA3B",
  color: "#000",
  "&:hover": {
    backgroundColor: "#FEDA3B",
    color: "#000",
  },
};

const DatagridHeader = () => (
  <TableHead sx={{ backgroundColor: "#1E1E1E" }}>
    <TableRow>
      <TableCell align="left">Run Name</TableCell>
      <TableCell align="center"></TableCell>
      <TableCell align="center"></TableCell>
      <TableCell align="left">Driver</TableCell>
      <TableCell align="center">Laps N.</TableCell>
      <TableCell align="center">Tyre Set</TableCell>
      <TableCell align="center">Heating</TableCell>
      <TableCell align="center">Vechicle Setup</TableCell>
      <TableCell align="center">Actions</TableCell>
    </TableRow>
  </TableHead>
);

const MyDatagridRow = ({ record, id, children, event, program }) => {
  const redirect = useRedirect();
  return (
    <RecordContextProvider value={record}>
      <TableRow
        hover={true}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          if (program?.status !== "Planned") {
            redirect(`/events/${event.id}/show/session/run/${record.id}`);
            localStorage.setItem("run", "completed");
          }
          if (record?.clickablerow) {
            redirect(`/events/${event.id}/show/session/run/${record.id}`);
            localStorage.setItem("run", "completed");
          }
        }}
      >
        {React.Children.map(children, (field) => (
          <TableCell key={`${id}-${field.props.source}`}>{field}</TableCell>
        ))}
      </TableRow>
    </RecordContextProvider>
  );
};

const MyDatagridBody = ({ program, event, ...props }) => (
  <DatagridBody
    {...props}
    row={<MyDatagridRow event={event} program={program} />}
  />
);

const List = ({ record, program, setOpen }) => {
  const [value, setValue] = useState("GT3");
  const redirect = useRedirect();

  const handleOpen = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const vehicleFilter = (e) => {
    setValue(e.target.id);
  };

  return (
    <ListBase resource="planned" filter={{ category: value }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: 4 }}
      >
        <Typography
          variant="body2"
          sx={{ fontSize: "22px", fontWeight: "bold" }}
        >
          Planned Runs
        </Typography>

        <Paper
          sx={{
            width: "auto",
            height: "30px",
            padding: "5px",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Button
              id="GT3"
              onClick={vehicleFilter}
              sx={value === "GT3" ? activeStyle : {}}
            >
              GT3
            </Button>

            <Button
              id="GT4"
              onClick={vehicleFilter}
              sx={value === "GT4" ? activeStyle : {}}
            >
              GT4
            </Button>
          </Stack>
        </Paper>
      </Stack>

      <Card
        sx={{
          border: "1px solid #1E1E1E",
          background: "transparent",
        }}
      >
        <Datagrid
          body={<MyDatagridBody event={record} program={program} />}
          header={<DatagridHeader />}
        >
          <FunctionField
            render={(record) => (
              <Typography variant="body2" sx={{ textAlign: "left" }}>
                {record.name}
              </Typography>
            )}
          />

          <TextField />
          <TextField />

          <FunctionField
            render={(record) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
              >
                <Avatar src={`/${record.driver.image[0].sm}`} />
                <Typography variant="body2" sx={{ fontSize: "14px" }}>
                  {record.driver.name}
                </Typography>
              </Stack>
            )}
          />

          <FunctionField
            render={(record) => (
              <InputBase
                defaultValue={record.laps}
                align="center"
                inputProps={{
                  sx: {
                    textAlign: "center",
                  },
                }}
                sx={{
                  backgroundColor: "#1E1E1E",
                  color: "#fff",
                  width: "63px",
                  borderRadius: "8px",
                }}
              />
            )}
          />

          <FunctionField
            render={(setup) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="body2" sx={{ fontSize: "14px" }}>
                  {setup.tyreset}
                </Typography>
                <Button
                  onClick={(e) => {
                    redirect(
                      `/events/${record.id}/show/session/tyre-pressure/${setup.id}`
                    );
                    e.stopPropagation();
                  }}
                  sx={{
                    fontSize: "14px",
                    color: "#FEDA3B",
                    textDecoration: "underline",
                    textTransform: "lowercase",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  pressures
                </Button>
              </Stack>
            )}
          />
          <FunctionField render={(record) => (record.heating ? "yes" : "no")} />

          <FunctionField
            render={(setup) => (
              <Button
                onClick={(e) => {
                  redirect(
                    `/events/${record.id}/show/session/vechicle-setup/${setup.id}`
                  );
                  e.stopPropagation();
                }}
                sx={{
                  fontSize: "14px",
                  color: "#FEDA3B",
                  textDecoration: "underline",
                  textTransform: "lowercase",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {`Setup #${setup.id}`}
              </Button>
            )}
          />

          <FunctionField
            render={(setup) =>
              setup.status && program?.status === "Planned" ? (
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  sx={{
                    "&.MuiButton-contained": {
                      borderRadius: "12px",
                      width: "114px",
                      height: "31px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "#000",
                      backgroundColor: "#fff",
                    },
                  }}
                >
                  Start run
                </Button>
              ) : !setup.heating && program?.status === "Planned" ? (
                <Button
                  variant="contained"
                  sx={{
                    "&.MuiButton-contained": {
                      borderRadius: "12px",
                      width: "114px",
                      height: "31px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "#4A4A4A",
                      backgroundColor: "#252525",
                    },
                  }}
                >
                  Start run
                </Button>
              ) : (
                <IconButton
                  onClick={() => {
                    redirect(
                      `/events/${record.id}/show/session/run/${setup.id}`
                    );
                    localStorage.setItem("run", "completed");
                  }}
                >
                  <EastIcon color="primary" />
                </IconButton>
              )
            }
          />
        </Datagrid>

        <Button
          sx={{
            fontSize: "14px",
            margin: "16px 0 16px 10px",
            textDecoration: "underline",
            textTransform: "capitalize",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >{`* Add Run`}</Button>
      </Card>

      {program?.status === "Planned" && (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%" }}
        >
          <Button
            sx={{
              my: "30px",
              "&.MuiButton-contained": {
                width: "195px",
              },
            }}
            variant="contained"
          >
            Save
          </Button>
        </Stack>
      )}
    </ListBase>
  );
};

export default List;
