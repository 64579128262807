import {
  Stack,
  Paper,
  Typography,
  Divider,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";
import { format } from "date-fns";
import Image from "mui-image";

const weather = [
  { id: 1, title: "Ambient", value: "36° C" },
  { id: 2, title: "Weather", value: "Cloudy" },
  { id: 3, title: "Track Conditions", value: "Clean" },
  { id: 4, title: "Asphalt", value: "40° C" },
  { id: 5, title: "Humidity", value: "40° C" },
  { id: 6, title: "Atmosphere Pressure", value: "980 mbar" },
];

const SessionDetail = ({ record, program }) => {
  return (
    <Paper sx={{ height: "177.7px", my: 4, p: 1 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Box
          sx={{
            maxWidth: "312px",
            height: "177.7px",
            backgroundColor: "#fff",
            borderRadius: "4px",
            overflow: "hidden",
          }}
        >
          <Image
            duration={0}
            fit="contain"
            src={`/${record?.image[0]?.detail}`}
            alt=""
          />
        </Box>

        <Stack direction="column" sx={{ maxWidth: "100%" }} spacing={1}>
          <div>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "#999999" }}
            >
              {program?.timing}
            </Typography>
            <Typography variant="body2" color="primary">
              {record?.start && `${format(new Date(record?.end), "dd MMM")}`}
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {record?.location}
            </Typography>
          </div>
          <Divider />
          <Stack direction="row" spacing={3}>
            <div>
              <Typography
                variant="subtitle2"
                color="primary"
                sx={{ fontSize: "11px" }}
              >
                Event Type
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {record?.event}
              </Typography>
            </div>
            <div>
              <Typography
                variant="subtitle2"
                color="primary"
                sx={{ fontSize: "11px" }}
              >
                Championship
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {record?.title}
              </Typography>
            </div>
          </Stack>
        </Stack>

        <Divider
          orientation="vertical"
          variant="middle"
          sx={{ height: "137px", mt: "auto" }}
        />

        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minWidth: "350px" }}
        >
          <Grid
            container
            columnSpacing={1}
            rowSpacing={5}
            sx={{ width: "100%" }}
          >
            {weather.map((item) => (
              <Grid xs={4} item>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  sx={{ fontSize: "11px" }}
                >
                  {item.title}
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {item.value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Stack>

        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minWidth: "235px", width: "322px" }}
        >
          <TableContainer
            sx={{
              border: "1px solid #464646",
              borderRadius: "4px",

              width: "100%",
            }}
          >
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#464646" }}>
                <TableRow>
                  <TableCell sx={{ fontSize: "12px" }}>Indicators</TableCell>
                  <TableCell sx={{ fontSize: "12px" }}>Estimated</TableCell>
                  <TableCell sx={{ fontSize: "12px" }}>Real</TableCell>
                </TableRow>
              </TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "12px" }}>Time</TableCell>
                <TableCell sx={{ fontSize: "12px" }}>20 min</TableCell>
                <TableCell sx={{ fontSize: "12px" }}>20 min</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontSize: "12px" }}>Fuel</TableCell>
                <TableCell sx={{ fontSize: "12px" }}>199 kg</TableCell>
                <TableCell sx={{ fontSize: "12px" }}>199 kg</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontSize: "12px" }}>Distance</TableCell>
                <TableCell sx={{ fontSize: "12px" }}>99 km</TableCell>
                <TableCell sx={{ fontSize: "12px" }}>99 km</TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default SessionDetail;
