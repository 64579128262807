import { useGetOne } from "react-admin";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ToolBar from "./ToolBar";
import SessionDetail from "./SessionDetail";
import List from "./List";
import RunModal from "./RunModal";
import { useState } from "react";

const Session = () => {
  const [open, setOpen] = useState(false);

  const { id: eventId } = useParams();
  const { data: record } = useGetOne("events", {
    id: eventId,
  });
  const { data: _program } = useGetOne("program", {
    id: localStorage.getItem("progrmaId"),
  });
  const [program, setProgram] = useState(_program);

  return (
    <>
      <ToolBar record={record} program={program} />

      <NavTabs setProgram={setProgram} program={program} />

      <SessionDetail record={record} program={program} />

      <List record={record} program={program} setOpen={setOpen} />

      {open && <RunModal open={open} setOpen={setOpen} record={record} />}
    </>
  );
};

function NavTabs({ program, setProgram }) {
  const [value, setValue] = useState(program.activity);
  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
          setProgram({ ...program, activity: event.target.innerText });
        }}
        {...props}
      />
    );
  }
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", borderBottom: "1px solid #1E1E1E" }}>
      <Tabs value={value} onChange={handleChange}>
        <LinkTab label="FP3" value="FP3" />
        <LinkTab label="Qualification - GT3" value="QUALIFICATION-GT3" />
        <LinkTab label="Qualification - GT4" value="QUALIFICATION-GT4" />
      </Tabs>
    </Box>
  );
}

export default Session;
