import { useGetOne, useRedirect } from "react-admin";
import { Stack, IconButton, Avatar, Box } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import UploadData from "../../UploadData/UploadData";

const Tech = () => {
  const { data } = useGetOne("garage", { id: localStorage.getItem("carId") });
  const redirect = useRedirect();
  const carData = {
    description: data?.description,
    attachments: data?.attachments,
    links: data?.links,
  };

  return (
    <Box>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 2 }}
        alignItems="center"
        mb={4}
      >
        <IconButton onClick={() => redirect(`/garage/${data?.id}/show`)}>
          <KeyboardArrowLeft fontSize="large" />
        </IconButton>
        <Avatar
          src={`/${data?.image[0]?.sm}`}
          sx={{ width: "60px", height: "60px" }}
        />
        <Stack>
          <span style={{ color: "#FEDA3B", fontSize: "12px" }}>
            {`Garage / ${data?.brand}`}
          </span>
          <span
            style={{
              fontSize: "25px",
              fontWeight: "bold",
              margin: "10px 0",
            }}
          >
            {`Fiche & Tech Manual`}
          </span>
        </Stack>
      </Stack>

      <UploadData {...carData} />
    </Box>
  );
};

export default Tech;
