import { Stack, Paper, IconButton } from "@mui/material";
import { FilterForm, SearchInput } from "react-admin";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const ToolBar = ({ nextMonth, prevMonth, title }) => {
  return (
    <Stack mb={4}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <h2 style={{ margin: "0" }}>Attendance Calendar</h2>
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Paper
            sx={{
              width: "100%",
              minWidth: "250px",
              height: "35px",
              padding: "4px",
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={1}
              direction="row"
            >
              <IconButton size="small" color="primary" onClick={prevMonth}>
                <KeyboardArrowLeftIcon />
              </IconButton>
              <Stack justifyContent="center" alignItems="center" width={150}>
                <h3 style={{ margin: "0" }}>{title}</h3>
              </Stack>
              <IconButton size="small" color="primary" onClick={nextMonth}>
                <KeyboardArrowRightIcon />
              </IconButton>
            </Stack>
          </Paper>
          <FilterForm
            sx={{
              alignItems: "center",
              "& .RaFilterFormInput-spacer": {
                display: "none",
              },
            }}
            filters={[
              <SearchInput
                InputLabelProps={{ shrink: true }}
                size="small"
                color="secondary"
                variant="filled"
                source="q"
                alwaysOn
                placeholder="Search"
                sx={{
                  "&.MuiTextField-root": {
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    border: "1px solid #fff",
                    width: "200px",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#BDC8D1",
                  },
                  input: {
                    color: "#000",
                    "&:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 100px #fff inset",
                      WebkitTextFillColor: "#000",
                    },
                  },
                }}
              />,
            ]}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ToolBar;
