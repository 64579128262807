import { useGetOne, useGetList } from "react-admin";
import { useParams } from "react-router-dom";

import ToolBar from "./ToolBar";
import VechicleTabs from "./VechicleTabs.js";

import { useEffect, useState } from "react";

const TelemetryNotes = () => {
  const { data: garage } = useGetList("garage");
  const [garageData, setGarageData] = useState([]);
  const { id: eventId } = useParams();
  const { data: record } = useGetOne("events", {
    id: eventId,
  });
  const { data: program } = useGetOne("program", {
    id: localStorage.getItem("progrmaId"),
  });

  useEffect(() => {
    setGarageData(
      garage?.filter((vehicle) => vehicle.category === record?.champioship)
    );
  }, [garage, record?.champioship]);

  return (
    <>
      <ToolBar program={program} record={record} />
      <VechicleTabs garageData={garageData} />
    </>
  );
};

export default TelemetryNotes;
