import React from "react";
import {
  Datagrid,
  FunctionField,
  TextField,
  ListBase,
  ChipField,
  RecordContextProvider,
  DatagridBody,
  useRedirect,
} from "react-admin";
import { Card, Avatar, TableHead, TableRow, TableCell } from "@mui/material";
import EastIcon from "@mui/icons-material/East";

import ToolBar from "./ToolBar/ToolBar";

const DatagridHeader = () => (
  <TableHead sx={{ backgroundColor: "#1E1E1E" }}>
    <TableRow>
      <TableCell align="left">Picture</TableCell>
      <TableCell align="center">Member</TableCell>
      <TableCell align="center">Role</TableCell>
      <TableCell align="center">Email</TableCell>
      <TableCell align="center">Phone</TableCell>
      <TableCell align="center">Actions</TableCell>
    </TableRow>
  </TableHead>
);

const MyDatagridRow = ({ record, id, children }) => {
  const redirect = useRedirect();
  return (
    <RecordContextProvider value={record}>
      <TableRow
        hover={true}
        onClick={() => redirect(`/members/${id}/show`)}
        sx={{ cursor: "pointer" }}
      >
        {React.Children.map(children, (field) => (
          <TableCell key={`${id}-${field.props.source}`}>{field}</TableCell>
        ))}
      </TableRow>
    </RecordContextProvider>
  );
};

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);

const MembersList = () => (
  <ListBase>
    <ToolBar />
    <Card
      sx={{
        border: "1px solid #1E1E1E",
        background: "transparent",
      }}
    >
      <Datagrid body={<MyDatagridBody />} header={<DatagridHeader />}>
        <FunctionField
          render={(record) => (
            <Avatar variant="circular" src={record.image[0].sm} />
          )}
        />

        <TextField source="name" />

        <ChipField
          source="role"
          sx={{
            backgroundColor: "#1E1E1E",
            color: "#fff",
            width: "140px",
            borderRadius: "8px",
          }}
        />
        <TextField source="email" />
        <TextField source="phone" />

        <FunctionField render={() => <EastIcon color="primary" />} />
      </Datagrid>
    </Card>
  </ListBase>
);

export default MembersList;
