import { Menu, useLogout, useNotify } from "react-admin";
import { Tooltip } from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useCheckAccessCode from "../../hooks/useCheckAccessCode";

const menuStyle = {
  "&.MuiList-root": {
    width: "60px",
    height: "300px",
    display: "flex",
    direction: "cloumn",
    justifyContent: "space-between",
    margin: "0",
  },
};
const iconStyle = {
  marginLeft: '1px'
}

const SideMenu = (props) => {
  const location = useLocation();
  const notify = useNotify();
  const logout = useLogout();
  const [prevPath, setPrevPath] = useState();
  const [validateAccessCodeRequest] = useCheckAccessCode();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") return;
    if (prevPath !== location.pathname) {
      setPrevPath(location.pathname);
      (async () => {
        const data = await validateAccessCodeRequest({
          code: localStorage.getItem("code"),
        });
        if (!data) {
          notify(`The access-code has expired!`, { type: "error" });
          logout();
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Menu {...props} sx={menuStyle}>
      <Tooltip title="Calendar">
        <Menu.Item to="/events" leftIcon={<TodayIcon sx={iconStyle} />} />
      </Tooltip>
      <Tooltip title="Garage">
        <Menu.Item to="/garage" leftIcon={<DirectionsCarIcon sx={iconStyle} />} />
      </Tooltip>
      <Tooltip title="Members">
        <Menu.Item to="/members" leftIcon={<PeopleAltIcon sx={iconStyle} />} />
      </Tooltip>
      <Tooltip title="Inventory">
        <Menu.Item to="/inventory" leftIcon={<Inventory2OutlinedIcon sx={iconStyle} />} />
      </Tooltip>
    </Menu>
  );
};

export default SideMenu;
