import {
  ShowBase,
  TextField,
  useShowController,
  useRedirect,
} from "react-admin";
import {
  Stack,
  IconButton,
  Box,
  Avatar,
  Grid,
  Paper,
  Link,
  Button,
} from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import LinkIcon from "@mui/icons-material/Link";
import { saveAs } from "file-saver";

const InventoryShow = (props) => {
  const { record } = useShowController();
  const redirect = useRedirect();

  return (
    <ShowBase {...props}>
      <Box>
        <Stack direction="row" spacing={1} alignItems="end">
          <IconButton onClick={() => redirect("/inventory")}>
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>
          <Stack>
            <span
              style={{ color: "#FEDA3B", fontSize: "12px" }}
            >{`Inventory`}</span>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                margin: "10px 0",
              }}
            >
              Item Detail
            </span>
          </Stack>
        </Stack>

        <Box
          sx={{
            width: "100%",
            height: "200px",
            border: "1px solid #707070",
            margin: "30px 0",
            borderRadius: "8px",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Avatar
              src={record?.image[0]?.md}
              sx={{
                objectFit: "contain",
                width: "300px",
                height: "200px",
                borderRadius: "8px 0  0 8px",
              }}
              variant="square"
            />
            <Stack
              width="100%"
              p={8}
              direction="row"
              alignItems="center"
              spacing={{ xs: 2, sm: 4, md: 8 }}
            >
              <Stack>
                <div style={{ color: "#FEDA3B", fontSize: "12px" }}>
                  Item Name
                </div>
                <TextField
                  source="name"
                  sx={{ fontSize: "20px", fontWeight: "bold" }}
                />
              </Stack>

              <Stack>
                <div style={{ color: "#FEDA3B", fontSize: "12px" }}>
                  Category
                </div>
                <TextField
                  source="category"
                  sx={{ fontSize: "20px", fontWeight: "bold" }}
                />
              </Stack>

              <Stack>
                <div style={{ color: "#FEDA3B", fontSize: "12px" }}>Date</div>
                <TextField
                  source="date"
                  sx={{ fontSize: "20px", fontWeight: "bold" }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Box>

        <Grid container spacing={{ xs: 1, sm: 2, md: 4 }} direction="row">
          <Grid xs={4} item>
            <Box
              p={4}
              sx={{
                maxWidth: "100%",
                height: "400px",
                border: "1px solid #707070",
                borderRadius: "8px",
              }}
            >
              <div style={{ color: "#FEDA3B", fontSize: "12px" }}>
                Information
              </div>
              <p>{record?.description}</p>
            </Box>
          </Grid>
          <Grid xs={4} item>
            <Box
              p={4}
              sx={{
                maxWidth: "100%",
                height: "400px",
                border: "1px solid #707070",
                borderRadius: "8px",
              }}
            >
              <div style={{ color: "#FEDA3B", fontSize: "12px" }}>
                Attachements
              </div>
              {record?.attachments?.length &&
                record?.attachments.map((file) => (
                  <Paper sx={{ my: 2, borderRadius: "8px" }} key={file.id}>
                    <Button
                      component={Link}
                      sx={{
                        p: 3,
                        borderRadius: "8px",
                        justifyContent: "space-between",
                      }}
                      color="secondary"
                      fullWidth
                      onClick={() => saveAs(file.url, file.title)}
                    >
                      <span>{file.title}</span>
                      <IconButton
                        sx={{
                          backgroundColor: "#000",
                          "&:hover": { backgroundColor: "#000" },
                        }}
                      >
                        <SaveAltIcon color="primary" />
                      </IconButton>
                    </Button>
                  </Paper>
                ))}
            </Box>
          </Grid>
          <Grid xs={4} item>
            <Box
              p={4}
              sx={{
                maxWidth: "100%",
                height: "400px",
                border: "1px solid #707070",
                borderRadius: "8px",
              }}
            >
              <div style={{ color: "#FEDA3B", fontSize: "12px" }}>Links</div>
              {record?.links?.length &&
                record?.links.map((link) => (
                  <Paper sx={{ my: 2, borderRadius: "8px" }} key={link.id}>
                    <Button
                      href={link.url}
                      component={Link}
                      sx={{
                        p: 3,
                        borderRadius: "8px",
                        justifyContent: "space-between",
                      }}
                      color="secondary"
                      fullWidth
                      target="_blank"
                    >
                      <span>{link.name}</span>
                      <IconButton
                        sx={{
                          backgroundColor: "#000",
                          "&:hover": { backgroundColor: "#000" },
                        }}
                      >
                        <LinkIcon color="primary" />
                      </IconButton>
                    </Button>
                  </Paper>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ShowBase>
  );
};

export default InventoryShow;
