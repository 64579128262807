import { useGetOne, useGetList } from "react-admin";
import { useParams } from "react-router-dom";

import ToolBar from "./ToolBar";
import DriveTabs from "./DriveTabs";
import { useEffect, useState } from "react";
import { Avatar, Stack } from "@mui/material";

const DriverReport = () => {
  const { data: members } = useGetList("members");
  const { data: driverReport } = useGetList("driverReport");
  const [drivers, setDrivers] = useState(null);
  const { id: eventId } = useParams();
  const { data: record } = useGetOne("events", {
    id: eventId,
  });
  const { data: program } = useGetOne("program", {
    id: localStorage.getItem("progrmaId"),
  });

  useEffect(() => {
    setDrivers(members?.filter((member) => member.role === "Driver"));
  }, [members]);

  return (
    <>
      <ToolBar program={program} record={record} />
      <Stack direction="row" alignItems="flex-start" spacing={6} sx={{ ml: 7 }}>
        <Avatar
          src={`/${record?.image[0]?.detail}`}
          sx={{
            objectFit: "contain",
            width: "360px",
            height: "200px",
            backgroundColor: "#fff",
            borderRadius: "4px",
          }}
          variant="square"
        />
        <DriveTabs drivers={drivers} driverReport={driverReport} />
      </Stack>
    </>
  );
};

export default DriverReport;
