import React from "react";
import { Stack, Avatar, Chip } from "@mui/material";

import {
  Card,
  TableHead,
  TableRow,
  TableCell,
  Button,
  InputBase,
} from "@mui/material";

import {
  DatagridBody,
  Datagrid,
  FunctionField,
  RecordContextProvider,
  ListBase,
} from "react-admin";

const DatagridHeader = () => (
  <TableHead sx={{ backgroundColor: "#1E1E1E" }}>
    <TableRow>
      <TableCell align="left">Turn</TableCell>
      <TableCell align="center">Turn</TableCell>
      <TableCell align="center">Min Speed</TableCell>
      <TableCell align="center">Max Brake</TableCell>
      <TableCell align="center">Gear</TableCell>
      <TableCell align="left">Note</TableCell>
    </TableRow>
  </TableHead>
);

const MyDatagridRow = ({ record, id, children }) => {
  return (
    <RecordContextProvider value={record}>
      <TableRow hover={true} sx={{ cursor: "pointer" }}>
        {React.Children.map(children, (field, index) => (
          <TableCell
            key={`${id}-${field.props.source}`}
            sx={{
              "&.MuiTableCell-body": {
                textAlign: index === 5 ? "left" : "center",
              },
            }}
          >
            {field}
          </TableCell>
        ))}
      </TableRow>
    </RecordContextProvider>
  );
};

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);

const List = () => {
  return (
    <ListBase resource="telemetry">
      <Card
        sx={{
          border: "1px solid #1E1E1E",
          background: "transparent",
        }}
      >
        <Datagrid body={<MyDatagridBody />} header={<DatagridHeader />}>
          <FunctionField
            render={(record) => (
              <Avatar
                src={`/${record.image}`}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                }}
                variant="square"
              />
            )}
          />

          <FunctionField
            render={(record) => (
              <Chip
                label={`#${record.id}`}
                sx={{
                  backgroundColor: "#1E1E1E",
                  color: "#fff",
                  width: "40px",
                  height: "40px",
                  borderRadius: "8px",
                }}
              />
            )}
          />

          <FunctionField
            render={(record) => (
              <InputBase
                defaultValue={record?.minSpeed}
                placeholder="Min Speed.."
                align="center"
                inputProps={{
                  sx: {
                    textAlign: "center",
                  },
                }}
                sx={{
                  backgroundColor: "#fff",
                  color: "#000",
                  height: "23px",
                  width: "81px",
                  borderRadius: "8px",
                  fontSize: "12px",
                }}
              />
            )}
          />

          <FunctionField
            render={(record) => (
              <InputBase
                defaultValue={record?.maxBrake}
                placeholder="Max Brake.."
                align="center"
                inputProps={{
                  sx: {
                    textAlign: "center",
                  },
                }}
                sx={{
                  backgroundColor: "#fff",
                  color: "#000",
                  height: "23px",
                  width: "81px",
                  borderRadius: "8px",
                  fontSize: "12px",
                }}
              />
            )}
          />

          <FunctionField
            render={(record) => (
              <InputBase
                defaultValue={record?.gear}
                placeholder="Gear.."
                align="center"
                inputProps={{
                  sx: {
                    textAlign: "center",
                  },
                }}
                sx={{
                  backgroundColor: "#fff",
                  color: "#000",
                  height: "23px",
                  width: "81px",
                  borderRadius: "8px",
                  fontSize: "12px",
                }}
              />
            )}
          />

          <FunctionField
            label="note"
            render={(record) => (
              <InputBase
                defaultValue={record?.note}
                placeholder="Note.."
                align="center"
                inputProps={{
                  sx: {
                    textAlign: "left",
                  },
                }}
                sx={{
                  backgroundColor: "#fff",
                  color: "#000",
                  height: "23px",
                  width: "241px",
                  borderRadius: "8px",
                  fontSize: "12px",
                  pl: "5px",
                }}
              />
            )}
          />
        </Datagrid>
        <Button
          sx={{
            fontSize: "14px",
            margin: "16px 0 16px 10px",
            color: "#FEDA3B",
            textDecoration: "underline",
            textTransform: "capitalize",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          Add Turn
        </Button>
      </Card>
      <Stack alignItems="center" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          sx={{
            my: "37px",
            "&.MuiButton-contained": {
              width: "195px",
            },
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </ListBase>
  );
};

export default List;
