import { useState, useEffect } from "react";
import { FilterForm, SearchInput, useListContext } from "react-admin";
import { Stack, Paper, Button } from "@mui/material";

const activeStyle = {
  backgroundColor: "#FEDA3B",
  color: "#000",
  "&:hover": {
    backgroundColor: "#FEDA3B",
    color: "#000",
  },
};

const ToolBar = () => {
  const [value, setValue] = useState("");
  const { setFilters } = useListContext();

  const handleFilter = (event) => {
    setValue(event.target?.id);
    if (event.target?.id === "All") setFilters([], {});
    else {
      setFilters({ category: event.target?.id });
    }
  };

  useEffect(() => {
    setFilters([], {});
    setValue("All");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
      justifyContent="space-between"
      alignItems="center"
      mb={4}
    >
      <Stack direction="column" alignItems="flex-start" justifyContent="center">
        <h2 style={{ margin: "0" }}>Garage</h2>
      </Stack>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Paper
          sx={{
            width: "auto",
            height: "30px",
            padding: "5px",
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={1}
            direction="row"
          >
            <Button
              variant="text"
              id="All"
              onClick={handleFilter}
              sx={value === "All" ? activeStyle : {}}
            >
              ALL
            </Button>

            <Button
              variant="text"
              id="GT3"
              onClick={handleFilter}
              sx={value === "GT3" ? activeStyle : {}}
            >
              GT3
            </Button>
            <Button
              variant="text"
              id="GT4"
              onClick={handleFilter}
              sx={value === "GT4" ? activeStyle : {}}
            >
              GT4
            </Button>
            <Button
              variant="text"
              id="GT Cup"
              onClick={handleFilter}
              sx={value === "GT Cup" ? activeStyle : {}}
            >
              GT Cup
            </Button>
          </Stack>
        </Paper>
        <FilterForm
          sx={{
            alignItems: "center",
            "& .RaFilterFormInput-spacer": {
              display: "none",
            },
          }}
          filters={[
            <SearchInput
              InputLabelProps={{ shrink: true }}
              size="small"
              color="secondary"
              variant="filled"
              source="q"
              alwaysOn
              placeholder="Search"
              sx={{
                "&.MuiTextField-root": {
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  border: "1px solid #fff",
                  width: "200px",
                },
                "& .MuiSvgIcon-root": {
                  color: "#BDC8D1",
                },
                input: {
                  color: "#000",
                  "&:-webkit-autofill": {
                    WebkitBoxShadow: "0 0 0 100px #fff inset",
                    WebkitTextFillColor: "#000",
                  },
                },
              }}
            />,
          ]}
        />
      </Stack>
    </Stack>
  );
};

export default ToolBar;
