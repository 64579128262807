import { Stack, IconButton } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

import { useRedirect } from "react-admin";

const ToolBar = ({ program, record, session }) => {
  const redirect = useRedirect();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ mb: 3 }}
    >
      <Stack direction="row" spacing={1} alignItems="end">
        <IconButton
          onClick={() => redirect(`/events/${record?.id}/show/session`)}
        >
          <KeyboardArrowLeft fontSize="large" />
        </IconButton>

        <Stack>
          <span style={{ color: "#FEDA3B", fontSize: "12px" }}>
            {`Calendar / ${record?.title} / ${program?.activity}`}
          </span>
          <span
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              margin: "10px 0",
            }}
          >
            {`Tyre Pressure Calculation - Set #${session?.id}`}
          </span>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ToolBar;
