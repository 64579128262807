import React, { useEffect, useState } from "react";
import {
  Button,
  RecordContextProvider,
  Datagrid,
  FunctionField,
  DatagridBody,
  TextField,
  ChipField,
  ListContextProvider,
  useList,
} from "react-admin";
import {
  Stack,
  Paper,
  Modal,
  IconButton,
  Typography,
  Card,
  Avatar,
  TableHead,
  TableRow,
  TableCell,
  Switch,
  Link,
  InputBase,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EuroIcon from "@mui/icons-material/Euro";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 3,
  width: "950px",
  borderRadius: "8px",
};

const DatagridHeader = () => (
  <TableHead sx={{ backgroundColor: "#000" }}>
    <TableRow>
      <TableCell align="left">Picture</TableCell>
      <TableCell align="center">Member</TableCell>
      <TableCell align="center">Role</TableCell>
      <TableCell align="center">Payment</TableCell>
      <TableCell align="center">Amount</TableCell>
      <TableCell align="left">Status</TableCell>
      <TableCell align="center"></TableCell>
    </TableRow>
  </TableHead>
);

const MyDatagridRow = ({ record, id, children }) => {
  return (
    <RecordContextProvider value={record}>
      <TableRow>
        {React.Children.map(children, (field) => (
          <TableCell key={`${id}-${field.props.source}`}>{field}</TableCell>
        ))}
      </TableRow>
    </RecordContextProvider>
  );
};

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);

const PaymentsModal = ({ open, setOpen, attendeesMembers }) => {
  const [data, setData] = useState([]);
  const listContext = useList({ data });
  const handleClose = () => setOpen(false);

  const handlePayments = (e) => {
    setData((current) =>
      current.map((item) => {
        if (item.id.toString() === e.target.id) {
          return { ...item, payment: !item.payment };
        }

        return item;
      })
    );
  };

  const handleAmount = (e) => {
    setData((current) =>
      current.map((item) => {
        if (item.id.toString() === e.target.id) {
          return { ...item, amount: e.target.value };
        }

        return item;
      })
    );
  };

  useEffect(() => {
    setData(attendeesMembers);
  }, [attendeesMembers]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            PAYMENTS STATUS
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              color: "#fff",
              backgroundColor: "#000",
              fontSize: "20px",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>
        <ListContextProvider value={listContext}>
          <Card
            sx={{
              border: "1px solid #1E1E1E",
              background: "#000",
              margin: "20px 0",
              borderRadius: "8px",
            }}
          >
            <Datagrid body={<MyDatagridBody />} header={<DatagridHeader />}>
              <FunctionField
                render={(record) => (
                  <Avatar variant="circular" src={record.image[0].sm} />
                )}
              />

              <TextField source="name" />

              <ChipField
                source="role"
                sx={{
                  backgroundColor: "#1E1E1E",
                  color: "#fff",
                  width: "120px",
                  borderRadius: "8px",
                }}
              />
              <TextField source="Vat" />

              <FunctionField
                render={(record) =>
                  record.payment ? (
                    <Chip
                      icon={<EuroIcon sx={{ fontSize: 12 }} />}
                      label={record.payment ? record?.amount : ""}
                      sx={{
                        backgroundColor: "#1E1E1E",
                        color: "#fff",
                        width: "100px",
                        borderRadius: "8px",
                      }}
                    />
                  ) : (
                    <InputBase
                      id={record.id}
                      size="small"
                      onChange={handleAmount}
                      sx={{
                        width: "100px",
                        backgroundColor: "#707070",
                        borderRadius: "8px",
                        borderColor: "#707070",
                        p: "4px",
                      }}
                    />
                  )
                }
              />

              <FunctionField
                label="Status"
                render={(record) => (
                  <>
                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          color: record?.payment ? "#fff" : "#FF0000",
                        }}
                      >
                        {record?.payment ? "PAID" : "TO PAY"}
                      </Typography>

                      {record?.payment ? (
                        <Link
                          href={record?.attachments?.url}
                          target="_blank"
                          rel="noopener"
                        >
                          Attachments
                        </Link>
                      ) : (
                        <Link sx={{ cursor: "pointer" }}>Add+</Link>
                      )}
                    </Stack>
                  </>
                )}
              />

              <FunctionField
                render={(record) => (
                  <Switch
                    id={record.id}
                    checked={record.payment}
                    onClick={handlePayments}
                  />
                )}
              />
            </Datagrid>
          </Card>
        </ListContextProvider>
        <Stack justifyContent="center" alignItems="center">
          <Button variant="contained" onClick={handleClose}>
            Save
          </Button>
        </Stack>
      </Paper>
    </Modal>
  );
};

export default PaymentsModal;
