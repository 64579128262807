import { Paper, Box, Grid, Stack, Typography, Divider } from "@mui/material";
import DropZone from "../../../../DropeZone/DropeZone";
import Car from "../../../../../assets/images/car.png";

const cardStyle = {
  minWidth: "237px",
  maxWidth: "400px",
  height: "214px",
};

const data = [
  { id: 1, title: "Caster", value: "7°" },
  { id: 2, title: "Camber", value: "2°" },
  { id: 3, title: "Toe", value: "2", unit: "mm" },
  { id: 4, title: "Height", value: "133", unit: "mm" },
  { id: 5, title: "Bump", value: "7/25" },
  { id: 6, title: "Rebound", value: "43/45" },
  { id: 7, title: "Spring", value: "99", unit: "Nm" },
  { id: 8, title: "Rubber", value: "No" },
  { id: 9, title: "Weight", value: "344", unit: "kg" },
  { id: 10, title: "ARB", value: "Mid" },
  { id: 11, title: "Pad Mix", value: "End" },
];

const VechicleCard = ({ title }) => {
  return (
    <Paper sx={cardStyle}>
      <Typography
        color="primary"
        variant="body2"
        sx={{ fontSize: "14px", fontWeight: "bold", m: "8px 13px" }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "177px",
          borderRadius: "0 0 4px 4px",
          backgroundColor: "#fff",
        }}
      >
        <Grid container rowSpacing={2} sx={{ margin: "0", p: "8px 15px" }}>
          {data.map((item) => (
            <Grid item key={item.id} xs={3} zeroMinWidth>
              <Typography
                noWrap
                variant="body2"
                sx={{ fontSize: "9px", color: "#707070" }}
              >
                {item.title}
              </Typography>

              <Typography
                noWrap
                variant="body2"
                sx={{
                  fontSize: "14px",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {item.value}
                <span style={{ fontSize: "10px" }}>{item.unit}</span>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
};
const Vechicle = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ my: 4 }}
      spacing={4}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2.2}
        width="100%"
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <VechicleCard title="FRONT LEFT" />
          <VechicleCard title="REAR LEFT" />
        </Stack>

        <Stack
          sx={{
            minWidth: "285px",
            maxWidth: "500px",
            width: "100%",
            height: "452px",
          }}
        >
          <Paper sx={{ width: "100%", height: "452px" }}>
            <Typography
              color="primary"
              variant="body2"
              sx={{ fontSize: "14px", fontWeight: "bold", m: "8px 13px" }}
            >
              VEHICLE UP VIEW
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "415px",
                borderRadius: "0 0 4px 4px",
                backgroundColor: "#fff",
              }}
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#000",
                    my: 1,
                  }}
                >
                  1200 mm
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "#000",
                      transform: "rotate(90deg)",
                    }}
                  >
                    3000 mm
                  </Typography>
                  <img src={Car} alt="" />
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "#000",
                      transform: "rotate(90deg)",
                    }}
                  >
                    3000 mm
                  </Typography>
                </Stack>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#000",
                    mt: 1.5,
                  }}
                >
                  1200 mm
                </Typography>
              </Stack>
            </Box>
          </Paper>
        </Stack>

        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <VechicleCard title="FRONT RIGHT" />
          <VechicleCard title="REAR RIGHT" />
        </Stack>
      </Stack>

      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <Paper sx={{ width: "187px", height: "432px", p: "10px 20px" }}>
          <div>
            <Typography
              color="primary"
              variant="body2"
              sx={{ fontSize: "14px", fontWeight: "bold" }}
            >
              SETTINGS
            </Typography>
            <Divider />
            <Grid container p={2} spacing={2}>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "9px", fontWeight: "bold", color: "#6E6E6E" }}
                >
                  Brake
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  52%
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "9px", fontWeight: "bold", color: "#6E6E6E" }}
                >
                  Abs
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  3/7
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "9px", fontWeight: "bold", color: "#6E6E6E" }}
                >
                  Traction
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Off
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "9px", fontWeight: "bold", color: "#6E6E6E" }}
                >
                  Diff
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  5/5
                </Typography>
              </Grid>
            </Grid>
          </div>

          <div>
            <Typography
              color="primary"
              variant="body2"
              sx={{ fontSize: "14px", fontWeight: "bold" }}
            >
              ENGINE
            </Typography>
            <Divider />
            <Grid container p={2} spacing={2}>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "9px", fontWeight: "bold", color: "#6E6E6E" }}
                >
                  Restrict
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  55Mm
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "9px", fontWeight: "bold", color: "#6E6E6E" }}
                >
                  Mapping
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  2/3
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "9px", fontWeight: "bold", color: "#6E6E6E" }}
                >
                  Carb
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Lean
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "9px", fontWeight: "bold", color: "#6E6E6E" }}
                >
                  Spark Add
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  42°
                </Typography>
              </Grid>
            </Grid>
          </div>

          <div>
            <Typography
              color="primary"
              variant="body2"
              sx={{ fontSize: "14px", fontWeight: "bold" }}
            >
              DASH CONF
            </Typography>
            <Divider />
            <Grid container p={2} spacing={2}>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "9px", fontWeight: "bold", color: "#6E6E6E" }}
                >
                  Name
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Fp1
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "9px", fontWeight: "bold", color: "#6E6E6E" }}
                >
                  File
                </Typography>

                <DropZone
                  title="Upload File"
                  styleTitle={{
                    fontSize: "12px",
                    fontWeight: "400",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Stack>
    </Stack>
  );
};

export default Vechicle;
