export const engine = [
  { id: 1, title: "Engine block" },
  { id: 2, title: "Radiators and intercoolers" },
  { id: 3, title: "Turbochargers and exhaust pipes" },
  { id: 4, title: "Oil pump" },
  { id: 5, title: "Vacuum pump" },
  { id: 6, title: "Water pump" },
  { id: 7, title: "Alternator" },
  { id: 8, title: "A/C compressor" },
  { id: 9, title: "Fuel pumps" },
  { id: 10, title: "Injectors / Carburetor" },
  { id: 11, title: "Spark plugs" },
  { id: 12, title: "Oil and filter" },
  { id: 13, title: "Air filter" },
  { id: 14, title: "Belts" },
  { id: 15, title: "Supports" },
];

export const transmission = [
  { id: 1, title: "Gearbox" },
  { id: 2, title: "Gear ratio" },
  { id: 3, title: "Actuator" },
  { id: 4, title: "Gearbox oil and filter" },
  { id: 5, title: "Transmission shaft" },
  { id: 6, title: "Clutch" },
  { id: 7, title: "Clutch actuator" },
  { id: 8, title: "Drive shafts" },
  { id: 9, title: "Differential" },
  { id: 10, title: "Differential ramps" },
  { id: 11, title: "Differential oil and filter" },
  { id: 12, title: "Differential pump" },
];

export const brakes = [
  { id: 1, title: "Calipers" },
  { id: 2, title: "Master cylinders" },
  { id: 3, title: "Distributor" },
  { id: 4, title: "Oil" },
  { id: 5, title: "Discs" },
  { id: 6, title: "Pads" },
];

export const suspensions = [
  { id: 1, title: "Dampers" },
  { id: 2, title: "Dampers joints and arms" },
  { id: 3, title: "Wheel carriers" },
  { id: 4, title: "Wheel hubs and bearings" },
  { id: 5, title: "Wheel studs" },
  { id: 6, title: "Steering pump" },
  { id: 7, title: "Steering joints" },
  { id: 8, title: "Steering oil" },
];

export const fia = [
  { id: 1, title: "Driver seat" },
  { id: 2, title: "Seat belts" },
  { id: 3, title: "Door net" },
  { id: 4, title: "Fuel tank" },
  { id: 5, title: "Fire extinguisher" },
  { id: 6, title: "Rollbar" },
];

export const chassis = [
  { id: 1, title: "Rear wing mounts" },
  { id: 2, title: "Front splitter mounts" },
  { id: 3, title: "Chassis" },
];
