import React from "react";
import { Stack, Paper, IconButton } from "@mui/material";

import { format, add } from "date-fns";

import {
  Card,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Chip,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import {
  useShowController,
  DatagridBody,
  ListBase,
  Datagrid,
  FunctionField,
  TextField,
  RecordContextProvider,
  useRedirect,
} from "react-admin";

const DatagridHeader = () => (
  <TableHead sx={{ backgroundColor: "#1E1E1E" }}>
    <TableRow>
      <TableCell align="left">Date</TableCell>
      <TableCell align="center">Timing</TableCell>
      <TableCell align="center"></TableCell>
      <TableCell align="center"></TableCell>
      <TableCell align="center"></TableCell>
      <TableCell align="center">Activity</TableCell>
      <TableCell align="center"></TableCell>
      <TableCell align="center"></TableCell>
      <TableCell align="center"></TableCell>
      <TableCell align="center">Category</TableCell>
      <TableCell align="left">Status</TableCell>
      <TableCell align="center">Actions</TableCell>
    </TableRow>
  </TableHead>
);

const MyDatagridRow = ({ record, id, children }) => {
  const { record: event } = useShowController();
  const redirect = useRedirect();
  return (
    <RecordContextProvider value={record}>
      <TableRow
        hover={true}
        sx={{ cursor: "pointer" }}
        onClick={(e) => {
          if (record.session && record.status === "Completed") {
            redirect(`/events/${event?.id}/show/session`);
            localStorage.setItem("progrmaId", record.id);
          }
        }}
      >
        {React.Children.map(children, (field) => (
          <TableCell key={`${id}-${field.props.source}`}>{field}</TableCell>
        ))}
      </TableRow>
    </RecordContextProvider>
  );
};

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);

const ProgramList = ({ session }) => {
  const redirect = useRedirect();
  const { record: event } = useShowController();

  const handleModal = (e) => {
    localStorage.setItem("progrmaId", e.target.id);
    redirect(`/events/${event?.id}/show/session`);
    e.stopPropagation();
  };

  return (
    <ListBase
      resource="program"
      filter={{ session: !session ? null : session }}
    >
      <Card
        sx={{
          border: "1px solid #1E1E1E",
          background: "transparent",
        }}
      >
        <Datagrid body={<MyDatagridBody />} header={<DatagridHeader />}>
          <FunctionField
            render={(record) => (
              <Paper
                sx={{
                  backgroundColor: record.session ? "#fff" : "#1E1E1E",
                  color: record.session ? "#000" : "#fff",
                  borderRadius: "50%",
                  width: "25px",
                  height: "25px",
                  p: "8px",
                  fontSize: "10px",
                }}
              >
                {format(
                  new Date(
                    record.status === "Completed" && !record.session
                      ? event?.start
                      : record.session && record.status === "Completed"
                      ? add(new Date(event?.start), { days: 1 })
                      : event?.end
                  ),
                  "dd MMM"
                )}
              </Paper>
            )}
          />
          <TextField source="timing" />
          <TextField />
          <TextField />
          <TextField />
          <TextField source="activity" />
          <TextField />
          <TextField />
          <TextField />

          <FunctionField
            render={(record) => (
              <Chip
                label={record?.category}
                sx={{
                  backgroundColor: "#1E1E1E",
                  color: "#fff",
                  width: "70px",
                  borderRadius: "8px",
                }}
              />
            )}
          />

          <FunctionField
            label="Status"
            render={(record) => (
              <>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <CircleIcon
                    sx={{
                      color:
                        record.status === "Completed" ? "#0A9F20" : "#FEDA3B",
                      fontSize: "8px",
                    }}
                  />
                  <span>{record.status}</span>
                </Stack>
              </>
            )}
          />
          <FunctionField
            render={(record) =>
              record.session && record?.status === "Planned" ? (
                <Button
                  id={record.id}
                  onClick={handleModal}
                  sx={{
                    "&.MuiButton-contained": {
                      width: "140px",
                      height: "35px",
                      fontSize: "14px",
                      borderRadius: "12px",
                    },
                  }}
                  variant="contained"
                  endIcon={<ArrowRightIcon />}
                >
                  Start Session
                </Button>
              ) : (
                record.session && (
                  <IconButton
                    id={record.id}
                    onClick={(e) => {
                      redirect(`/events/${event?.id}/show/session`);
                      localStorage.setItem("progrmaId", e.currentTarget.id);
                      e.stopPropagation();
                    }}
                  >
                    <EastIcon color="primary" />
                  </IconButton>
                )
              )
            }
          />
        </Datagrid>
      </Card>
    </ListBase>
  );
};

export default ProgramList;
