import { Button, Box, Grid, Stack, Avatar, IconButton } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { useGetOne, useRedirect } from "react-admin";

import { ReactComponent as BrakesIcon } from "../../../assets/svg/brakes.svg";
import { ReactComponent as EngineIcon } from "../../../assets/svg/engine.svg";
import { ReactComponent as ChassisIcon } from "../../../assets/svg/chassis.svg";
import Fia from "../../../assets/svg/fia.png";
import { ReactComponent as SuspensionIcon } from "../../../assets/svg/suspension.svg";
import { ReactComponent as TransmissionIcon } from "../../../assets/svg/tarnsmission.svg";

const linkStyle = {
  backgroundColor: "#262626",
  height: "300px",
  width: "100%",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "50px",
};

const Mileage = () => {
  const { data } = useGetOne("garage", { id: localStorage.getItem("carId") });
  const redirect = useRedirect();

  return (
    <Box>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 2 }}
        alignItems="center"
        mb={4}
      >
        <IconButton onClick={() => redirect(`/garage/${data?.id}/show`)}>
          <KeyboardArrowLeft fontSize="large" />
        </IconButton>
        <Avatar
          src={`/${data?.image[0]?.sm}`}
          sx={{ width: "60px", height: "60px" }}
        />
        <Stack>
          <span style={{ color: "#FEDA3B", fontSize: "12px" }}>
            {`Garage / ${data?.brand}`}
          </span>
          <span
            style={{
              fontSize: "25px",
              fontWeight: "bold",
              margin: "10px 0",
            }}
          >
            Components Mileage
          </span>
        </Stack>
      </Stack>

      <Grid container spacing={2} direction="column">
        <Grid container item spacing={2}>
          <Grid xs={4} item>
            <Button
              onClick={() =>
                redirect(`/garage/${data?.id}/show/mileage/engine-system`)
              }
              sx={linkStyle}
            >
              <EngineIcon fill="#8E8E8E" />
              <span
                style={{
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                Engine System
              </span>
            </Button>
          </Grid>
          <Grid xs={4} item>
            <Button
              onClick={() =>
                redirect(`/garage/${data?.id}/show/mileage/transmission-system`)
              }
              sx={linkStyle}
            >
              <TransmissionIcon fill="#8E8E8E" />
              <span
                style={{
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                Transmission System
              </span>
            </Button>
          </Grid>
          <Grid xs={4} item>
            <Button
              onClick={() =>
                redirect(`/garage/${data?.id}/show/mileage/brakes-system`)
              }
              sx={linkStyle}
            >
              <BrakesIcon fill="#8E8E8E" />
              <span
                style={{
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                Brakes System
              </span>
            </Button>
          </Grid>
        </Grid>

        <Grid container item spacing={2}>
          <Grid xs={4} item>
            <Button
              onClick={() =>
                redirect(`/garage/${data?.id}/show/mileage/suspensions`)
              }
              sx={linkStyle}
            >
              <SuspensionIcon fill="#8E8E8E" />
              <span
                style={{
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                Suspensions
              </span>
            </Button>
          </Grid>
          <Grid xs={4} item>
            <Button
              onClick={() => redirect(`/garage/${data?.id}/show/mileage/fia`)}
              sx={linkStyle}
            >
              <img
                src={Fia}
                alt=""
                style={{ width: "110px", marginBottom: "14px" }}
              />
              <span
                style={{
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                Fia Homologations - Leaded Components
              </span>
            </Button>
          </Grid>
          <Grid xs={4} item>
            <Button
              onClick={() =>
                redirect(`/garage/${data?.id}/show/mileage/chassis-system`)
              }
              sx={linkStyle}
            >
              <ChassisIcon fill="#8E8E8E" />
              <span
                style={{
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                {`Bodywork & Chassis`}
              </span>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Mileage;
