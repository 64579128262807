import { useState } from "react";
import { useGetOne, ShowBase, useRedirect } from "react-admin";
import { Stack, IconButton, Avatar, Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import FormTab from "./Tabs/Tabs";

const TyresShow = (props) => {
  const [value, setValue] = useState("FL");
  const redirect = useRedirect();
  const { id } = useParams();
  const { data } = useGetOne("garage", { id: localStorage.getItem("carId") });
  const { data: record } = useGetOne("tyres", { id: id });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ShowBase resource="tyres" {...props}>
      <Box>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
          alignItems="center"
          mb={4}
        >
          <IconButton
            onClick={() => redirect(`/garage/${data?.id}/show/tyres`)}
          >
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>
          <Avatar
            src={`/${data?.image[0]?.sm}`}
            sx={{ width: "60px", height: "60px" }}
          />

          <Stack>
            <span style={{ color: "#FEDA3B", fontSize: "12px" }}>
              {`Garage / ${data?.brand} / ${record?.name}`}
            </span>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                margin: "10px 0",
              }}
            >
              {` Tyres Set (${record?.id})`}
            </span>
          </Stack>
        </Stack>

        {/* <Stack my={4}>
          <span>General Tyre Set</span>
          <FormControlLabel
            control={
              <Checkbox
                checkedIcon={<CheckBoxOutlined color="primary" />}
                checked={record?.ontrack ? 1 : 0}
              />
            }
            label="Set loaded on Truck"
          />
        </Stack> */}

        <TabContext value={value}>
          <Box
            sx={{
              border: "1px solid #707070",
              width: "400px",
              marginBottom: "20px",
            }}
          >
            <TabList value={value} onChange={handleChange}>
              <Tab label="FL" value="FL" />

              <Tab label="FR" value="FR" />

              <Tab label="RL" value="RL" />

              <Tab label="RR" value="RR" />
            </TabList>
          </Box>
          <TabPanel value="FL" sx={{ padding: "24px 0" }}>
            <span>Front Left</span>
            <FormTab />
          </TabPanel>
          <TabPanel value="FR" sx={{ padding: "24px 0" }}>
            <span>Front Right</span>
            <FormTab />
          </TabPanel>
          <TabPanel value="RL" sx={{ padding: "24px 0" }}>
            <span>Rear Left</span>
            <FormTab />
          </TabPanel>
          <TabPanel value="RR" sx={{ padding: "24px 0" }}>
            <span>Rear Right</span>
            <FormTab />
          </TabPanel>
        </TabContext>
      </Box>
    </ShowBase>
  );
};

export default TyresShow;
