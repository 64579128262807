import { useGetOne } from "react-admin";
import { useParams } from "react-router-dom";

import ToolBar from "./ToolBar";
import Detail from "./Detail";
import Vechicle from "./Vechicle";

const VechicleSetup = () => {
  const { id: eventId } = useParams();
  const { sessionId } = useParams();
  const { data: record } = useGetOne("events", {
    id: eventId,
  });
  const { data: program } = useGetOne("program", {
    id: localStorage.getItem("progrmaId"),
  });

  const { data: session } = useGetOne("planned", {
    id: sessionId,
  });
  return (
    <>
      <ToolBar program={program} record={record} session={session} />
      <Detail />
      <Vechicle />
    </>
  );
};

export default VechicleSetup;
