import { useState, useCallback } from "react";
import {
  Stack,
  Box,
  Tab,
  TextField,
  Link,
  Paper,
  List,
  ListItem,
  IconButton,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const textBoxStyle = {
  "& label.Mui-focused": {
    color: "#FEDA3B",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#FEDA3B",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      height: "auto",
    },
    "&:hover fieldset": {
      borderColor: "#FEDA3B",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FEDA3B",
    },
  },
};

const UploadData = ({ attachments, description, links }) => {
  const [value, setValue] = useState(1);
  const [myFiles, setMyFiles] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const files = myFiles.map((file) => (
    <ListItem key={file.path} sx={{ color: "#FEDA3B" }}>
      <IconButton onClick={removeFile(file)} aria-label="delete" color="error">
        <RemoveCircleIcon />
      </IconButton>
      {file.path}
    </ListItem>
  ));

  return (
    <TabContext value={value}>
      <Box
        sx={{
          width: "750px",
          marginBottom: "20px",
        }}
      >
        <TabList
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label="DESCRIPTION" value={1} />

          <Tab label="ATTACHMENTS" value={2} />

          <Tab label="LINKS" value={3} />
        </TabList>
      </Box>

      <Box
        sx={{
          width: "100%",
          minHeight: "400px",
          border: "1px solid #707070",
          margin: "30px 0",
          overflowY: "auto",
        }}
      >
        <TabPanel value={1}>
          <TextField
            sx={textBoxStyle}
            fullWidth
            multiline
            id="description"
            variant="outlined"
            defaultValue={description}
          />
        </TabPanel>

        <TabPanel value={2}>
          <Paper
            fullWidth
            component="form"
            sx={{
              p: 2,
              minHeight: "70px",
            }}
          >
            <Stack
              fullWidth
              sx={{
                height: "60px",
                backgroundColor: "#121212",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            </Stack>
            <List>{files}</List>
          </Paper>

          <Stack direction="column" spacing={2}>
            <h4>Attached files:</h4>
            {attachments?.length &&
              attachments.map((file) => (
                <Link
                  href={file.url}
                  key={file.id}
                  target="_blank"
                  underline="none"
                  rel="noopener"
                  sx={{
                    "&.MuiLink-root": {
                      color: "#fff",
                    },
                  }}
                >
                  {file.title}
                </Link>
              ))}
          </Stack>
        </TabPanel>

        <TabPanel value={3}>
          <Stack direction="column" spacing={4}>
            {links?.length &&
              links.map((link) => {
                return (
                  <Link
                    key={link.id}
                    href={link.url}
                    underline="none"
                    target="_blank"
                    rel="noopener"
                    sx={{
                      "&.MuiLink-root": {
                        color: "#fff",
                      },
                    }}
                  >
                    {link.url}
                  </Link>
                );
              })}
          </Stack>
        </TabPanel>
      </Box>
    </TabContext>
  );
};

export default UploadData;
