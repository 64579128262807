import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  ListContextProvider,
  Datagrid,
  FunctionField,
  DatagridBody,
  TextField,
  ChipField,
  RecordContextProvider,
  useList,
} from "react-admin";
import {
  Stack,
  Paper,
  Modal,
  IconButton,
  Typography,
  Card,
  Avatar,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EastIcon from "@mui/icons-material/East";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 3,
  width: "850px",
  borderRadius: "8px",
};

const DatagridHeader = () => (
  <TableHead sx={{ backgroundColor: "#000" }}>
    <TableRow>
      <TableCell align="left">Picture</TableCell>
      <TableCell align="center">Member</TableCell>
      <TableCell align="center">Role</TableCell>
      <TableCell align="center">Travel By</TableCell>
      <TableCell align="center">Action</TableCell>
    </TableRow>
  </TableHead>
);

const MyDatagridRow = ({ record, id, children }) => {
  const navigate = useNavigate();
  return (
    <RecordContextProvider value={record}>
      <TableRow
        hover={true}
        sx={{ cursor: "pointer" }}
        onClick={() =>
          navigate(`/members/${record?.id}/show`, {
            state: { name: "attendees" },
          })
        }
      >
        {React.Children.map(children, (field) => (
          <TableCell key={`${id}-${field.props.source}`}>{field}</TableCell>
        ))}
      </TableRow>
    </RecordContextProvider>
  );
};

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);

const AttendModal = ({ open, setOpen, attendeesMembers }) => {
  const [data, setData] = useState([]);
  const listContext = useList({ data });
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setData(attendeesMembers);
  }, [attendeesMembers]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            EVENT ATTENDEES
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              color: "#fff",
              backgroundColor: "#000",
              fontSize: "20px",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>
        <ListContextProvider value={listContext}>
          <Card
            sx={{
              border: "1px solid #1E1E1E",
              background: "#000",
              margin: "20px 0",
              borderRadius: "8px",
            }}
          >
            <Datagrid body={<MyDatagridBody />} header={<DatagridHeader />}>
              <FunctionField
                render={(record) => (
                  <Avatar variant="circular" src={record.image[0].sm} />
                )}
              />

              <TextField source="name" />

              <ChipField
                source="role"
                sx={{
                  backgroundColor: "#1E1E1E",
                  color: "#fff",
                  width: "120px",
                  borderRadius: "8px",
                }}
              />
              <TextField source="travel" />

              <FunctionField render={() => <EastIcon color="primary" />} />
            </Datagrid>
          </Card>
        </ListContextProvider>
        <Stack justifyContent="center" alignItems="center">
          <Button variant="contained" onClick={handleClose}>
            Done
          </Button>
        </Stack>
      </Paper>
    </Modal>
  );
};

export default AttendModal;
