import Menu from "../SideMenu/SideMenu";
import {
  Grid,
  Divider,
  Box,
  Typography,
  Stack,
  Container,
} from "@mui/material";
import { styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logo from "../../assets/images/logo.png";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { AvatarMenu } from "../AvatarMenu/AvatarMenu";
import { useGetIdentity } from "react-admin";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  zIndex: 1,
  minHeight: "100vh",
  backgroundColor: "#000",
  position: "relative",
}));

const AppFrame = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
}));

const MyLayout = ({ children }) => {
  const { identity } = useGetIdentity();
  const theme = useTheme();
  const unsupportedScreen = useMediaQuery(theme.breakpoints.down("1200"));

  return (
    <Root>
      <AppFrame>
        {unsupportedScreen ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{
              minHeight: "100vh",
              backgroundColor: "#000",
              color: "#fff",
              padding: "10px",
            }}
          >
            <Box>
              <AspectRatioIcon sx={{ fontSize: 100 }} />
            </Box>
            <Typography
              variant="h4"
              component="h3"
              textAlign={"center"}
              paddingBottom={"5px"}
            >
              Aspect Ratio Not Supported
            </Typography>
            <Typography variant="h6" textAlign={"center"}>
              This resolution is not available in demo mode
            </Typography>
          </Grid>
        ) : (
          <Grid
            container
            direction={"row"}
            sx={{
              height: "100vh",
              backgroundColor: "#000",
              color: "#fff",
              minWidth: "1200px",
              minHeight: "768px",
            }}
          >
            <Grid item sx={{ position: "fixed", height: "100%" }}>
              <Stack
                alignItems="center"
                sx={{
                  height: "100%",
                  width: "70px",
                  paddingTop: "24px",
                }}
              >
                {!unsupportedScreen && (
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <AvatarMenu />
                    <Typography
                      variant="body1"
                      color="primary"
                      sx={{
                        fontSize: "12px",
                        textAlign: "center",
                        mt: 1,
                        lineHeight: 1,
                      }}
                    >
                      {identity?.team}
                    </Typography>

                    <Box marginTop={"130px"} paddingLeft={"2px"}>
                      <Menu />
                    </Box>

                    {!unsupportedScreen && (
                      <img
                        alt=""
                        src={Logo}
                        style={{
                          width: "50px",
                          left: "11px",
                          position: "absolute",
                          bottom: "20px",
                        }}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
            </Grid>

            {!unsupportedScreen && (
              <Divider orientation="vertical" flexItem sx={{ ml: "70px" }} />
            )}

            <Grid
              zeroMinWidth
              item
              xs={11}
              sx={{ paddingTop: "24px", paddingBottom: "10px" }}
            >
              <Container maxWidth={"xl"}>{children}</Container>
            </Grid>
          </Grid>
        )}

        {unsupportedScreen && (
          <img
            alt=""
            src={Logo}
            style={{
              width: "50px",
              left: "11px",
              position: "absolute",
              bottom: "20px",
            }}
          />
        )}
      </AppFrame>
    </Root>
  );
};

export default MyLayout;
