import { Stack, IconButton, Paper, Typography } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

import { useRedirect } from "react-admin";

const ToolBar = ({ program, record, session, run }) => {
  const redirect = useRedirect();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ mb: 3 }}
    >
      <Stack direction="row" spacing={1} alignItems="end">
        <IconButton
          onClick={() => redirect(`/events/${record?.id}/show/session`)}
        >
          <KeyboardArrowLeft fontSize="large" />
        </IconButton>

        <Stack>
          <span style={{ color: "#FEDA3B", fontSize: "12px" }}>
            {`Calendar / ${record?.title} / ${program?.activity}`}
          </span>
          <span
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              margin: "10px 0",
            }}
          >
            {`Run: ${session?.name}`}
          </span>
        </Stack>
      </Stack>

      <Paper
        sx={{
          width: "206px",
          height: "19px",
          borderRadius: "25px",
          py: "15px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Typography variant="body2" sx={{ fontSize: "14px" }}>
            STATUS:
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "#0A9F20",
            }}
          >
            {run === "running" ? "RUNNING" : "COMPLETED"}
          </Typography>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default ToolBar;
