import { getApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import { firebaseApp } from '../config/firebase';

const functions = getFunctions(getApp());
if (process.env.REACT_APP_LOCAL_FIREBSE === 'true') {
    connectFunctionsEmulator(functions, "localhost", 5001);
}


const useCheckAccessCode = () => {
    const [makeRequest, loading] = useHttpsCallable(
        getFunctions(firebaseApp),
        'isValidAccessCode',
    );

    return [
        makeRequest,
        loading
    ]
}

export default useCheckAccessCode;