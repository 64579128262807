import React, { useState, useRef } from "react";
import { format } from "date-fns";
import {
  Datagrid,
  FunctionField,
  TextField,
  ListBase,
  ChipField,
  RecordContextProvider,
  DatagridBody,
  useRedirect,
} from "react-admin";
import {
  Card,
  TableHead,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Stack,
  Typography,
  Paper,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import EastIcon from "@mui/icons-material/East";

import Calendar from "./ToolBar/Calendar/Calendar";
import ToolBar from "./ToolBar/ToolBar";
import EventModal from "./EventModal";

const DatagridHeader = () => (
  <TableHead sx={{ backgroundColor: "#1E1E1E" }}>
    <TableRow>
      <TableCell align="left">Date</TableCell>
      <TableCell align="center">Event</TableCell>
      <TableCell align="center">Event Type</TableCell>
      <TableCell align="center">Championship</TableCell>
      <TableCell align="center">Location</TableCell>
      <TableCell align="center"></TableCell>
      <TableCell align="left">Status</TableCell>
      <TableCell align="center">Actions</TableCell>
    </TableRow>
  </TableHead>
);

const MyDatagridRow = ({ record, id, children }) => {
  const redirect = useRedirect();
  return (
    <RecordContextProvider value={record}>
      <TableRow
        hover={record.status === "Accepted" ? true : false}
        sx={{ cursor: record.status === "Accepted" ? "pointer" : "default" }}
        onClick={() => {
          if (record.status === "Accepted")
            redirect(`/events/${record.id}/show`);
        }}
      >
        {React.Children.map(children, (field) => (
          <TableCell key={`${id}-${field.props.source}`}>{field}</TableCell>
        ))}
      </TableRow>
    </RecordContextProvider>
  );
};

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);

const EventsList = (props) => {
  const [title, setTitle] = useState(format(new Date(), "MMMM yyyy"));
  const [currentMonth, setCurrentMonth] = useState(format(new Date(), "MMMM"));
  const [calendarApi, SetCalendarApi] = useState();
  const redirect = useRedirect();
  const [open, setOpen] = useState(false);
  const [eventId, setEventId] = useState("");
  const calendarRef = useRef();

  const handleEvent = (e) => {
    setEventId(e?.currentTarget?.id);
    setOpen(true);
    e.stopPropagation();
  };

  const nextMonth = () => {
    calendarApi?.next();
    setTitle(calendarApi?.getCurrentData()?.viewTitle);
    setCurrentMonth(
      format(new Date(calendarApi?.getDate().toISOString()), "MMMM")
    );
  };

  const prevMonth = () => {
    calendarApi?.prev();
    setTitle(calendarApi?.getCurrentData()?.viewTitle);
    setCurrentMonth(
      format(new Date(calendarApi?.getDate().toISOString()), "MMMM")
    );
  };

  const showDeital = (e) => {
    redirect(`/events/${e?.currentTarget?.id}/show`);
  };

  return (
    <ListBase {...props} filter={{ month: currentMonth }}>
      <ToolBar nextMonth={nextMonth} prevMonth={prevMonth} title={title} />
      <Calendar ref={calendarRef} SetCalendarApi={SetCalendarApi} />
      <Typography
        variant="body2"
        sx={{ fontSize: "18px", fontWeight: "bold", my: "15px" }}
        color="primary"
      >
        EVENT LIST
      </Typography>
      <Card
        sx={{
          border: "1px solid #1E1E1E",
          background: "transparent",
        }}
      >
        <Datagrid body={<MyDatagridBody />} header={<DatagridHeader />}>
          <FunctionField
            label="Date"
            render={(record) => (
              <Paper
                sx={{
                  backgroundColor: "#fff",
                  color: "#000",
                  borderRadius: "50%",
                  width: "35px",
                  height: "35px",
                  p: "5px",
                }}
              >
                <Typography sx={{ fontSize: "9px", mt: 0.5 }}>
                  {`${format(new Date(record?.start), "dd")} - ${format(
                    new Date(record?.end),
                    "dd MMM"
                  )}`}
                </Typography>
              </Paper>
            )}
          />
          <FunctionField render={(record) => `event #${record.id}`} />

          <TextField source="event" />

          <ChipField
            source="title"
            sx={{
              backgroundColor: "#1E1E1E",
              color: "#fff",
              width: "200px",
              borderRadius: "8px",
            }}
          />
          <TextField source="location" />
          <TextField />
          <FunctionField
            label="Status"
            render={(record) => (
              <>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <CircleIcon
                    sx={{
                      color: record.textColor,
                      fontSize: "8px",
                    }}
                  />
                  <span>{record.status}</span>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Button
                    id={record.id}
                    onClick={handleEvent}
                    sx={{
                      fontSize: "11px",
                      color: "#FEDA3B",
                      textDecoration: "underline",
                      textTransform: "lowercase",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    edit
                  </Button>
                </Stack>
              </>
            )}
          />

          <FunctionField
            render={(record) =>
              record.status === "Accepted" && (
                <IconButton id={record.id} onClick={showDeital}>
                  <EastIcon color="primary" />
                </IconButton>
              )
            }
          />
        </Datagrid>
      </Card>
      {eventId && (
        <EventModal open={open} setOpen={setOpen} eventId={eventId} />
      )}
    </ListBase>
  );
};

export default EventsList;
