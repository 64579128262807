import { useGetOne } from "react-admin";
import { useParams } from "react-router-dom";

import ToolBar from "./ToolBar";
import Detail from "./Detail";
import List from "./List";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";

const Run = () => {
  const [run, setRun] = useState("running");
  const { id: eventId } = useParams();
  const { sessionId } = useParams();
  const { data: record } = useGetOne("events", {
    id: eventId,
  });
  const { data: program } = useGetOne("program", {
    id: localStorage.getItem("progrmaId"),
  });

  const { data: _session } = useGetOne("planned", {
    id: sessionId,
  });
  const [session, setSession] = useState(_session);

  useEffect(() => {
    setRun(localStorage.getItem("run"));
  }, []);

  return (
    <>
      <ToolBar program={program} record={record} session={session} run={run} />
      <NavTabs run={session} setRun={setSession} />
      <Detail record={record} run={run} />
      <List run={run} />
    </>
  );
};

export default Run;

function NavTabs({ run, setRun }) {
  const [value, setValue] = useState(run.name);
  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
          setRun({ ...run, name: event.target.innerText });
        }}
        {...props}
      />
    );
  }
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ width: "100%", marginBottom: 4, borderBottom: "1px solid #1E1E1E" }}
    >
      <Tabs value={value} onChange={handleChange}>
        <LinkTab label="Installation" value="Installation" />
        <LinkTab label="Setup Feedback 1" value="Setup Feedback 1" />
        <LinkTab label="Setup Feedback 2" value="Setup Feedback 2" />
      </Tabs>
    </Box>
  );
}
