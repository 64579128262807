import { Stack } from "@mui/material";

import Card from "./DetailCard";
import Tyres from "./Tyres";
import GeneralCard from "./GeneralCard";
import NotesCard from "./NotesCard";
import CircurtCard from "./CircurtCard";

const Detail = ({ record, run }) => {
  return (
    <Stack
      direction="row"
      spacing={1.5}
      alignItems="center"
      justifyContent="space-between"
    >
      <Card
        title={"TYRES"}
        cardWidth={"327px"}
        cardHeight={"385px"}
        content={<Tyres run={run} />}
      />
      <Stack
        direction="column"
        spacing={2.5}
        alignItems="center"
        justifyContent="center"
        width={"100%"}
      >
        <Card
          title={"GENERAL"}
          cardWidth={"521px"}
          cardHeight={"129px"}
          content={GeneralCard}
        />
        <Card
          title={"NOTES"}
          cardWidth={"521px"}
          cardHeight={"236px"}
          content={<NotesCard run={run} />}
        />
      </Stack>
      <Card
        title={"CIRCUIT"}
        cardWidth={"100%"}
        cardHeight={"385px"}
        content={<CircurtCard record={record} />}
      />
    </Stack>
  );
};

export default Detail;
