import { Paper, Stack, Divider } from "@mui/material";
import { Link } from "react-admin";

import { TyresCardContent } from "./Tyres";

const GeneralCard = (
  <Paper
    sx={{
      backgroundColor: "#fff",
      width: "100%",
      maxWidth: "521px",
      height: "93px",
    }}
  >
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-evenly"
      spacing={2}
      sx={{ py: "16px", px: 1 }}
    >
      <TyresCardContent title={"12"} subTitle={"RUN LAPS"} textColor={"#000"} />

      <TyresCardContent
        title={"55"}
        unit={"KM"}
        subTitle={"RUN DISTANCE"}
        textColor={"#000"}
      />

      <TyresCardContent
        title={"30"}
        unit={"KG"}
        subTitle={"FUEL REFILL"}
        textColor={"#000"}
      />

      <TyresCardContent
        title={"10"}
        unit={"KG"}
        subTitle={"REMAING FUEL"}
        textColor={"#000"}
      />

      <Divider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={{ borderRightColor: "#8A9EAD" }}
      />
      <Link to={"/events/3/show/session/vechicle-setup/2"}>
        <TyresCardContent
          title={"SETUP #2"}
          subTitle={"VEHICLE SETUP"}
          textColor={"#000"}
          isClickable={true}
        />
      </Link>
    </Stack>
  </Paper>
);

export default GeneralCard;
