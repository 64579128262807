import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  TextField,
  useShowController,
  ShowBase,
  useRedirect,
} from "react-admin";
import { Stack, IconButton, Box, Button } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { ReactComponent as Tech } from "../../assets/svg/tech.svg";
import { ReactComponent as Insurance } from "../../assets/svg/insurance.svg";
import { ReactComponent as Mileage } from "../../assets/svg/mileage.svg";
import { ReactComponent as Passport } from "../../assets/svg/passport.svg";
import { ReactComponent as Purchase } from "../../assets/svg/purchase.svg";
import { ReactComponent as Testbench } from "../../assets/svg/test.svg";
import { ReactComponent as Tyres } from "../../assets/svg/tyres.svg";

const linkStyle = {
  backgroundColor: "#262626",
  height: "200px",
  width: "50%",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
};

const GarageShow = (props) => {
  const { record } = useShowController();
  const redirect = useRedirect();
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("carId", record?.id);
  }, [record?.id]);

  return (
    <ShowBase {...props}>
      <Box>
        <Stack direction="row" spacing={1} alignItems="end">
          <IconButton
            onClick={() =>
              redirect(location.state.name === "session" ? -1 : "/garage")
            }
          >
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>

          <Stack>
            <span style={{ color: "#FEDA3B", fontSize: "12px" }}>
              {`Garage`}
            </span>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                margin: "10px 0",
              }}
            >
              Car Detail
            </span>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={{ xs: 2, sm: 4, md: 8 }}
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            width: "100%",
            height: "170px",
            border: "1px solid #707070",
            margin: "30px 0",
            borderRadius: "8px",
          }}
        >
          <Box sx={{ width: "258px", height: "172px" }}>
            <img
              src={record?.image[0]?.md}
              alt=""
              style={{
                width: "258px",
                height: "172px",
                borderRadius: "8px 0 0 8px",
              }}
            />
          </Box>

          <Stack
            width={"100%"}
            p={4}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Stack>
              <div style={{ color: "#FEDA3B", fontSize: "12px" }}>Brand</div>
              <TextField
                source="brand"
                sx={{ fontSize: "20px", fontWeight: "bold" }}
              />
            </Stack>

            <Stack>
              <div style={{ color: "#FEDA3B", fontSize: "12px" }}>VIN</div>
              <TextField
                source="VIN"
                sx={{ fontSize: "20px", fontWeight: "bold" }}
              />
            </Stack>

            <Stack>
              <div style={{ color: "#FEDA3B", fontSize: "12px" }}>Mileage</div>
              <TextField
                source="mileage"
                sx={{ fontSize: "20px", fontWeight: "bold" }}
              />
            </Stack>

            <Stack>
              <div style={{ color: "#FEDA3B", fontSize: "12px" }}>
                Consumption
              </div>
              <TextField
                source="consumption"
                sx={{ fontSize: "20px", fontWeight: "bold" }}
              />
            </Stack>

            <Stack>
              <div style={{ color: "#FEDA3B", fontSize: "12px" }}>Category</div>
              <TextField
                source="category"
                sx={{ fontSize: "20px", fontWeight: "bold" }}
              />
            </Stack>

            <Stack>
              <div style={{ color: "#FEDA3B", fontSize: "12px" }}>
                Telemetry
              </div>
              <TextField
                source="telemetry"
                sx={{ fontSize: "20px", fontWeight: "bold" }}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            width: "100%",
            height: "200px",
            margin: "10px 0",
          }}
        >
          <Button
            onClick={() => redirect(`/garage/${record?.id}/show/tyres`)}
            sx={linkStyle}
          >
            <Tyres fill="#8E8E8E" />
            <span
              style={{
                color: "white",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Tyres Diary
            </span>
          </Button>

          <Button
            onClick={() => redirect(`/garage/${record?.id}/show/mileage`)}
            sx={linkStyle}
          >
            <Mileage fill="#8E8E8E" />
            <span
              style={{
                color: "white",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Components Mileage
            </span>
          </Button>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            width: "100%",
            height: "200px",
          }}
        >
          <Button
            onClick={() => redirect(`/garage/${record?.id}/show/purchase`)}
            sx={linkStyle}
          >
            <Purchase fill="#8E8E8E" />
            <span
              style={{
                color: "white",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Purchase Or Rental Agreement
            </span>
          </Button>

          <Button
            onClick={() => redirect(`/garage/${record?.id}/show/insurance`)}
            sx={linkStyle}
          >
            <Insurance
              fill="#8E8E8E"
              style={{ width: "84px", height: "84px" }}
            />
            <span
              style={{
                color: "white",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Insurance
            </span>
          </Button>

          <Button
            onClick={() => redirect(`/garage/${record?.id}/show/passport`)}
            sx={linkStyle}
          >
            <Passport
              fill="#8E8E8E"
              style={{ width: "84px", height: "84px" }}
            />
            <span
              style={{
                color: "white",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Passport
            </span>
          </Button>

          <Button
            onClick={() => redirect(`/garage/${record?.id}/show/tech`)}
            sx={linkStyle}
          >
            <Tech
              sx={{ width: "84px", height: "84px", fill: "#8E8E8E" }}
              fill="#8E8E8E"
            />
            <span
              style={{
                color: "white",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Fiche and Tech Manual
            </span>
          </Button>

          <Button
            onClick={() => redirect(`/garage/${record?.id}/show/testbench`)}
            sx={linkStyle}
          >
            <Testbench
              sx={{ width: "84px", height: "84px", fill: "#8E8E8E" }}
              fill="#8E8E8E"
            />
            <span
              style={{
                color: "white",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Testbench Results
            </span>
          </Button>
        </Stack>
      </Box>
    </ShowBase>
  );
};

export default GarageShow;
