import { Paper, Stack } from "@mui/material";

const TyresCard = ({
  bgcolor,
  icon,
  title,
  fireIcon,
  moreIcon,
  cardContent,
  textColor,
  chipColor,
}) => {
  return (
    <Paper
      sx={{
        width: "137.5px",
        height: "149px",
        backgroundColor: bgcolor,
        p: 1,
        backgroundImage: "none",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
          sx={{
            width: "auto",
            height: "20px",
            backgroundColor: chipColor,
            borderRadius: "5px",
            color: textColor,
            fontWeight: "bold",
            p: "5px",
          }}
        >
          <span>{title}</span>
          {icon}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={0.5}
        >
          {fireIcon}
          {moreIcon}
        </Stack>
      </Stack>
      {cardContent}
    </Paper>
  );
};

export default TyresCard;
