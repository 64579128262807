import { useState, useEffect } from "react";
import {
  useShowController,
  useRedirect,
  useGetOne,
  useGetList,
} from "react-admin";
import DropZone from "../../DropeZone/DropeZone";
import {
  Stack,
  IconButton,
  Paper,
  Typography,
  Button,
  Link,
} from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PaymentsModal from "./PaymentsModal";
import { format } from "date-fns";

const buttonStyle = {
  "&.MuiButton-contained": {
    width: "100px",
    height: "35px",
    fontSize: "12px",
    borderRadius: "17px",
    backgroundColor: "#fff",
  },
};

const ToolBar = () => {
  const [attendeesMembers, setAttendeesMembers] = useState(null);
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState("");
  const { record } = useShowController();
  const { data: members } = useGetList("members");
  const { data } = useGetOne("members", {
    id: localStorage.getItem("memberId"),
  });

  const handleModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    setRole(localStorage.getItem("role"));
    setAttendeesMembers(members?.slice(0, record?.Attendees));
  }, [members, record?.Attendees]);

  const redirect = useRedirect();

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="end">
          <IconButton onClick={() => redirect("/events")}>
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>

          <Stack>
            <span style={{ color: "#FEDA3B", fontSize: "12px" }}>
              Attendance Calendar
            </span>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                margin: "10px 0",
              }}
            >
              {`Event Detail: ${record?.title} #${record?.id}`}
            </span>
          </Stack>
        </Stack>
        {role !== "Team manager" && data?.payment === false ? (
          <Paper sx={{ borderRadius: "25px" }}>
            <Stack
              direction="row"
              spacing={4}
              alignItems="center"
              justifyContent="center"
              p={1.5}
            >
              <Typography variant="body2" sx={{ color: "#FF0000" }}>
                PAYMENT PENDING
              </Typography>
              <Button
                variant="text"
                color="secondary"
                sx={{
                  textTransform: "lowercase",
                  textDecoration: "underline",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                <DropZone title={"add recipe"} />
              </Button>
            </Stack>
          </Paper>
        ) : (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            {role === "Team manager" && (
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  endIcon={<EditOutlinedIcon />}
                  sx={buttonStyle}
                >
                  Edit
                </Button>
                <Button
                  onClick={handleModal}
                  sx={buttonStyle}
                  variant="contained"
                  endIcon={<AttachMoneyIcon />}
                >
                  Payments
                </Button>
              </Stack>
            )}
            {role !== "Team manager" && data?.payment === true && (
              <Paper sx={{ borderRadius: "25px" }}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  p={1.5}
                >
                  <Typography variant="body2" sx={{ color: "#11FF00" }}>
                    PAYMENT RECEIVED:
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    $199,00
                  </Typography>
                  <Typography variant="body2">
                    VAT - {format(new Date(), "dd/MM/yyyy")}
                  </Typography>
                  <Link
                    href={data?.attachments?.url}
                    target="_blank"
                    rel="noopener"
                  >
                    {data?.attachments?.title}
                  </Link>
                </Stack>
              </Paper>
            )}
          </Stack>
        )}
        {open && role === "Team manager" && (
          <PaymentsModal
            open={open}
            setOpen={setOpen}
            attendeesMembers={attendeesMembers}
          />
        )}
      </Stack>
    </>
  );
};

export default ToolBar;
