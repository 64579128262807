import { useState } from "react";
import { ShowBase } from "react-admin";
import { Box, Switch, Stack, Typography, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import ToolBar from "./ToolBar";
import EventDetail from "./EventDetail";
import ProgramList from "./programList";
import VehicleList from "./VehicleList";

const EventsShow = (props) => {
  const [value, setValue] = useState("PROGRAM");
  const [session, setSession] = useState(false);

  const handleSession = () => {
    setSession(!session);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ShowBase {...props}>
      <Box>
        <ToolBar />
        <EventDetail />

        <TabContext value={value}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              borderBottom: "1px solid #1E1E1E",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <TabList value={value} onChange={handleChange}>
              <Tab key="PROGRAM" label="PROGRAM" value="PROGRAM" />
              <Tab key="VEHICLES" label="VEHICLES" value="VEHICLES" />
            </TabList>
            {value === "PROGRAM" && (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Switch onClick={handleSession} />
                <Typography variant="body2">Show Session Only</Typography>
              </Stack>
            )}
          </Stack>

          <TabPanel value="PROGRAM" sx={{ padding: "24px 0" }}>
            <ProgramList session={session} />
          </TabPanel>

          <TabPanel value="VEHICLES" sx={{ padding: "24px 0" }}>
            <VehicleList />
          </TabPanel>
        </TabContext>
      </Box>
    </ShowBase>
  );
};

export default EventsShow;
