import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Divider,
  Button,
  Box,
  Typography,
  Stack,
  Container,
} from "@mui/material";
import Logo from "../../assets/images/logo.png";
import { useGetIdentity, useRedirect } from "react-admin";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { AvatarMenu } from "../AvatarMenu/AvatarMenu";

const Welcome = () => {
  const { identity } = useGetIdentity();
  const theme = useTheme();
  const redirect = useRedirect();
  const unsupportedScreen = useMediaQuery(theme.breakpoints.down("1200"));

  return !unsupportedScreen ? (
    <>
      <Divider
        orientation={"horizontal"}
        flexItem
        sx={{
          position: "fixed",
          borderLeft: "solid",
          height: "100vh",
          left: "70px",
          borderWidth: "1px",
        }}
      />
      <Grid
        container
        direction={"row"}
        sx={{
          height: "100vh",
          backgroundColor: "#000",
          color: "#fff",
          paddingTop: "24px",
          paddingBottom: "10px",
        }}
      >
        <Grid item>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            sx={{ height: "100%", width: "70px" }}
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <AvatarMenu />
              <Typography
                variant="body1"
                color="primary"
                sx={{
                  fontSize: "12px",
                  textAlign: "center",
                  lineHeight: "1",
                  mt: 1,
                }}
              >
                {identity?.team}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item paddingLeft="114px" xs={11}>
          <Container maxWidth={"xl"}>
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              height="100vh"
              spacing={4}
              xs
              zeroMinWidth
              sx={{ marginTop: "-56px", marginLeft: "-20px" }}
            >
              <Grid
                container
                item
                xs={6}
                direction="column"
                justifyContent="flex-end"
                alignItems="center"
                spacing={4}
              >
                <Grid px={"0px!important"} item>
                  <h1
                    style={{
                      fontSize: "48px",
                      margin: "4px",
                      textAlign: "center",
                    }}
                  >
                    {`Welcome ${identity?.fullName}!`}
                  </h1>
                </Grid>
                <Grid paddingLeft="0px!important" item>
                  <p
                    style={{
                      maxWidth: "850px",
                      margin: "4px",
                      textAlign: "justify",
                    }}
                  >
                    This is the demo version where you can navigate core
                    functionalities of Racing Teams app. Enjoy your experience!
                  </p>
                </Grid>
              </Grid>
              <Grid
                justifyContent="center"
                alignItems="center"
                item
                xs={6}
                paddingLeft="0px!important"
              >
                <Button
                  onClick={() => redirect("/events")}
                  color="primary"
                  variant="contained"
                  sx={{ width: "160px!important" }}
                >
                  Let's start
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <img
          alt=""
          src={Logo}
          style={{
            width: "50px",
            left: "11px",
            position: "absolute",
            bottom: "20px",
          }}
        />
      </Grid>
    </>
  ) : (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{
        minHeight: "100vh",
        backgroundColor: "#000",
        color: "#fff",
        padding: "10px",
      }}
    >
      <Box>
        <AspectRatioIcon sx={{ fontSize: 100 }} />
      </Box>
      <Typography
        variant="h4"
        component="h3"
        textAlign={"center"}
        paddingBottom={"5px"}
      >
        Aspect Ratio Not Supported
      </Typography>
      <Typography variant="h6" textAlign={"center"}>
        This resolution is not available in demo mode
      </Typography>
      <img
        alt=""
        src={Logo}
        style={{
          width: "50px",
          left: "11px",
          position: "absolute",
          bottom: "20px",
        }}
      />
    </Grid>
  );
};

export default Welcome;
