import { useGetOne } from "react-admin";
import { Stack, Paper, Button, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";

const acceptedBtnStyle = {
  backgroundColor: "#FEDA3B",
  color: "#000",
  borderRadius: "26px",
  width: "133px",
  height: "52px",
  fontSize: "14px",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#FEDA3B",
  },
};

const btnStyle = {
  border: "1px solid #fff",
  color: "#000",
  backgroundColor: "#fff",
  borderRadius: "26px",
  width: "133px",
  height: "52px",
  fontSize: "14px",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#fff",
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 3,
  width: "650px",
  height: "300px",
  borderRadius: "8px",
};

const EventModal = ({ open, setOpen, eventId }) => {
  const { data } = useGetOne("events", { id: eventId });

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <span style={{ color: "#FEDA3B" }}>
              {`${format(new Date(data?.start), "dd")} - ${format(
                new Date(data?.end),
                "dd MMM"
              )}`}
            </span>

            <h3 style={{ margin: "5px 0 0 0" }}>{data?.title}</h3>

            <h5 style={{ color: "#888888", margin: "5px 0" }}>
              {`${data?.location}-${data?.title}`}
            </h5>
          </Stack>
          <IconButton
            onClick={handleClose}
            sx={{
              color: "#fff",
              backgroundColor: "#000",
              fontSize: "20px",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <p>
            “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.”
          </p>
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Button onClick={handleClose} sx={acceptedBtnStyle}>
            Accept
          </Button>
          {/*           <Button
            onClick={handleClose}
            style={data?.status === "Pending" ? acceptedBtnStyle : btnStyle}
          >
            Maybe
          </Button> */}
          <Button onClick={handleClose} sx={btnStyle}>
            Decline
          </Button>
        </Stack>
      </Paper>
    </Modal>
  );
};

export default EventModal;
