import { useState, useEffect } from "react";
import { useGetList, useShowController } from "react-admin";
import {
  Stack,
  Avatar,
  Button,
  Paper,
  Typography,
  Divider,
  Grid,
  Box,
} from "@mui/material";
import Image from "mui-image";
import { format } from "date-fns";

import AttendModal from "./AttendModal";

const EventDetail = () => {
  const [attendeesMembers, setAttendeesMembers] = useState(null);
  const [open, setOpen] = useState(false);
  const { record } = useShowController();
  const { data } = useGetList("members");

  const handleModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    setAttendeesMembers(data?.slice(0, record?.Attendees));
  }, [data, record?.Attendees]);

  return (
    <Paper sx={{ height: "177.7px", my: 4, p: 1 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={4}
      >
        <Box
          sx={{
            maxWidth: "312px",
            height: "177.7px",
            backgroundColor: "#fff",
            borderRadius: "4px",
            overflow: "hidden",
          }}
        >
          <Image
            duration={0}
            fit="contain"
            src={`/${record?.image[0]?.detail}`}
            alt=""
          />
        </Box>

        <Stack
          direction="column"
          sx={{ width: "500px", minWidth: "300px" }}
          spacing={2}
        >
          <div>
            <Typography variant="body2" color="primary">
              {record?.start &&
                `${format(new Date(record?.start), "dd")} - ${format(
                  new Date(record?.end),
                  "dd MMM"
                )}`}
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {record?.location}
            </Typography>
          </div>
          <Divider />
          <Stack direction="row" spacing={3}>
            <div>
              <Typography variant="subtitle2" color="primary">
                Event Type
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {record?.event}
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle2" color="primary">
                Championship
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {record?.title}
              </Typography>
            </div>
          </Stack>
        </Stack>

        <Stack
          direction="column"
          alignItems="center"
          sx={{ minWidth: "300px", maxWidth: "600px", height: "177.7px" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            my={0.3}
          >
            <Typography variant="subtitle2">Attendees</Typography>
            {attendeesMembers?.length > 6 && (
              <Button
                onClick={handleModal}
                variant="text"
                sx={{
                  textTransform: "lowercase",
                  color: "#FEDA3B",
                  textDecoration: "underline",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                view more
              </Button>
            )}
          </Stack>
          <Grid container spacing={1}>
            {attendeesMembers?.length &&
              attendeesMembers.slice(0, 6).map((element) => (
                <Grid item xs={6}>
                  <Paper
                    sx={{ backgroundColor: "#262626", borderRadius: "8px" }}
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      py={0.2}
                      px={1}
                    >
                      <Avatar src={element.image[0].sm} />
                      <Typography variant="subtitle2">
                        {element.name}
                      </Typography>
                    </Stack>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        </Stack>
      </Stack>
      {open && (
        <AttendModal
          open={open}
          setOpen={setOpen}
          attendeesMembers={attendeesMembers}
        />
      )}
    </Paper>
  );
};

export default EventDetail;
