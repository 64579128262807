import { useState } from "react";

import {
  Box,
  Stack,
  Tab,
  Table,
  TableCell,
  TableRow,
  Typography,
  Divider,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const tabelStyle = {
  color: "#707070",
  borderTop: "1px solid #8A9EAD1A",
  borderBottom: "none",
};

const DriveTabs = ({ drivers, driverReport }) => {
  const [value, setValue] = useState("Danica");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const DriverReport = ({ tyre }) => {
    return (
      <div>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: "16px 0 16px 16px" }}
        >
          <Typography
            color="primary"
            variant="body1"
            sx={{ fontSize: "22px", fontWeight: "bold" }}
          >
            {tyre}
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "13px", color: "#EBEBEB" }}
          >{`Ferrari Setup #1`}</Typography>
        </Stack>
        <Divider sx={{ borderBottomColor: "#707070" }} />
        <Table sx={{ my: "34.5px" }}>
          <TableRow>
            <TableCell sx={tabelStyle}>TURNS</TableCell>
            <TableCell sx={tabelStyle}>IN</TableCell>
            <TableCell sx={tabelStyle}>APEX</TableCell>
            <TableCell sx={tabelStyle}>OUT</TableCell>
            <TableCell sx={tabelStyle}>COMMENTS</TableCell>
          </TableRow>

          {driverReport?.length &&
            driverReport.map((item) => (
              <TableRow sx={{ border: "none" }} key={item.id}>
                <TableCell sx={{ color: item.color, border: "none" }}>
                  {item.turns}
                </TableCell>
                <TableCell sx={{ color: item.color, border: "none" }}>
                  {item.in}
                </TableCell>
                <TableCell sx={{ color: item.color, border: "none" }}>
                  {item.apex}
                </TableCell>
                <TableCell sx={{ color: item.color, border: "none" }}>
                  {item.out}
                </TableCell>
                <TableCell sx={{ color: item.color, border: "none" }}>
                  {item.comments}
                </TableCell>
              </TableRow>
            ))}
        </Table>
        <Divider sx={{ borderBottomColor: "#707070" }} />

        <Stack direction="row" alignItems="center" mt={3} spacing={12}>
          <Typography
            variant="body1"
            sx={{ fontSize: "16px", color: "#8A9EAD", fontWeight: "bold" }}
          >
            CURBS <span style={{ color: "#FFF" }}>GOOD</span>
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "16px", color: "#8A9EAD", fontWeight: "bold" }}
          >
            OVERALL <span style={{ color: "#FFF" }}>OK</span>
          </Typography>
        </Stack>
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: "1px solid #707070",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <TabList
            value={value}
            onChange={handleChange}
            scrollButtons="auto"
            variant="scrollable"
          >
            {drivers?.length &&
              drivers.map((item) => (
                <Tab key={item.id} label={item.name} value={item.name} />
              ))}
          </TabList>
        </Box>
        {drivers?.length &&
          drivers.map((item) => (
            <TabPanel
              key={item.name}
              value={item.name}
              sx={{ padding: "24px 0" }}
            >
              <Stack direction="row" alignItems="center" spacing={7}>
                <DriverReport tyre={"Used Tyres"} />
                <DriverReport tyre={"New Tyres"} />
              </Stack>
            </TabPanel>
          ))}
      </TabContext>
    </div>
  );
};

export default DriveTabs;
