import { Stack, Box, Paper, Typography, Divider } from "@mui/material";

const Detail = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ my: 4 }}
      spacing={4}
    >
      <Box
        sx={{
          width: "100%",
          height: "121px",
          border: "2px solid #1E1E1E",
          borderRadius: "4px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
          spacing={4}
          sx={{ p: "20px 15px" }}
        >
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-end"
            just
          >
            <Typography
              variant="body1"
              color="primary"
              sx={{ fontSize: "11px", fontWeight: "bold" }}
            >{`TYRE & PRESSURE`}</Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "36px",
                fontWeight: "bold",
              }}
            >
              SLICK
            </Typography>

            <Typography
              variant="body1"
              sx={{ fontSize: "11px", fontWeight: "bold" }}
            >
              TYPE
            </Typography>
          </Stack>

          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="flex-end"
              sx={{ marginTop: "16.5px" }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "36px", fontWeight: "bold" }}
              >
                1.6
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  paddingBottom: "6px",
                }}
              >
                BAR
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              sx={{ fontSize: "11px", fontWeight: "bold" }}
            >
              PRESSURE
            </Typography>
          </Stack>

          <Divider orientation="vertical" flexItem variant="middle" />

          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Typography
              variant="body1"
              color="primary"
              sx={{ fontSize: "11px", fontWeight: "bold" }}
            >
              WEIGHTS
            </Typography>

            <Stack direction="row" spacing={1} alignItems="flex-end">
              <Typography
                variant="body1"
                sx={{ fontSize: "36px", fontWeight: "bold" }}
              >
                1200
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  paddingBottom: "6px",
                }}
              >
                KG
              </Typography>
            </Stack>

            <Typography
              variant="body1"
              sx={{ fontSize: "11px", fontWeight: "bold" }}
            >
              TOTAL
            </Typography>
          </Stack>

          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="flex-end"
              sx={{ marginTop: "16.5px" }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "36px", fontWeight: "bold", color: "#636363" }}
              >
                80
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#636363",
                  paddingBottom: "6px",
                }}
              >
                KG
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              sx={{ fontSize: "11px", fontWeight: "bold" }}
            >
              DRIVER
            </Typography>
          </Stack>

          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "36px", fontWeight: "bold" }}
            ></Typography>
            <Stack
              direction="row"
              spacing={1}
              alignItems="flex-end"
              sx={{ marginTop: "16.5px" }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "36px", fontWeight: "bold", color: "#636363" }}
              >
                30
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#636363",
                  paddingBottom: "6px",
                }}
              >
                KG
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              sx={{ fontSize: "11px", fontWeight: "bold" }}
            >
              BALLAST
            </Typography>
          </Stack>

          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="flex-end"
              sx={{ marginTop: "16.5px" }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "36px", fontWeight: "bold", color: "#636363" }}
              >
                60
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#636363",
                  paddingBottom: "6px",
                }}
              >
                KG
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              sx={{ fontSize: "11px", fontWeight: "bold" }}
            >
              FUEL
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Stack>
        <Paper
          sx={{
            backgroundColor: "#FEDA3B",
            width: "195px",
            height: "85px",
            p: "19px 16px",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}
          >
            BALANCES
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-end"
            >
              <Stack direction="row" spacing={1} alignItems="flex-end">
                <Typography
                  variant="body1"
                  sx={{ fontSize: "38px", fontWeight: "bold", color: "#000" }}
                >
                  60
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#000",
                    paddingBottom: "10px",
                  }}
                >
                  %
                </Typography>
              </Stack>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", fontWeight: "bold", color: "#000" }}
              >
                AB
              </Typography>
            </Stack>

            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-end"
            >
              <Stack direction="row" spacing={1} alignItems="flex-end">
                <Typography
                  variant="body1"
                  sx={{ fontSize: "38px", fontWeight: "bold", color: "#000" }}
                >
                  52
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#000",
                    paddingBottom: "10px",
                  }}
                >
                  %
                </Typography>
              </Stack>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", fontWeight: "bold", color: "#000" }}
              >
                WD
              </Typography>
            </Stack>

            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-end"
            >
              <Stack direction="row" spacing={1} alignItems="flex-end">
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "38px",
                    fontWeight: "bold",
                    color: "#000",
                  }}
                >
                  48
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#000",
                    paddingBottom: "10px",
                  }}
                >
                  %
                </Typography>
              </Stack>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", fontWeight: "bold", color: "#000" }}
              >
                KF
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
};

export default Detail;
