const authProvider = {
  login: ({ username, code }) => {
    localStorage.setItem("username", username);
    localStorage.setItem("code", code);
    // accept all username/password combinations
    return Promise.resolve();
  },
  logout: ({ removeAccessCode } = {}) => {
    localStorage.removeItem("username");
    if (removeAccessCode) {
      localStorage.removeItem("code");
    }
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () =>
    localStorage.getItem("username") ? Promise.resolve() : Promise.reject(),
  getPermissions: () => Promise.reject("Unknown method"),
  getIdentity: () => {
    return Promise.resolve({
      id: localStorage.getItem("login"),
      fullName: localStorage.getItem("username"),
      team: "Carlin MotorSport",
    });
  },
};

export default authProvider;
