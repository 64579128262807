import { Paper, InputBase, Typography } from "@mui/material";

const NotesCard = ({ run }) => {
  return (
    <Paper
      sx={{
        backgroundColor: "#fff",
        width: "100%",
        maxWidth: "521px",
        height: "200px",
      }}
    >
      {run === "running" ? (
        <InputBase
          fullWidth
          placeholder="Driver feeling, technical intervention, data / video download.."
          sx={{ color: "#000", p: 1 }}
        />
      ) : (
        <Typography variant="body1" sx={{ color: "#000", p: 1 }}>
          Driver feeling, technical intervention, data / video download..
        </Typography>
      )}
    </Paper>
  );
};

export default NotesCard;
