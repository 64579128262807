import { useState } from "react";

import { Button, Grid, TextField, FormControl, MenuItem } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const Tabs = () => {
  const [date, setDate] = useState(null);
  return (
    <FormControl fullWidth>
      <Grid container spacing={2} py={2}>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth label="Brand" variant="outlined" />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Wear" variant="outlined" />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth select label="Race" variant="outlined">
              <MenuItem value="Race">Race</MenuItem>
              <MenuItem value="Test">Test</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth select label="Assembled" variant="outlined">
              <MenuItem value="Assembled on wheels - TPMS">
                Assembled on wheels - TPMS
              </MenuItem>
              <MenuItem value="Assembled on wheels">
                Assembled on wheels
              </MenuItem>
              <MenuItem value="Not assembled on wheels">
                Not assembled on wheels
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth label="Brno" variant="outlined" />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth variant="outlined" />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth label="Bar Code" variant="outlined" />
          </Grid>
        </Grid>
      </Grid>
      <Button variant="contained" sx={{ margin: "20px auto" }}>
        Save
      </Button>
    </FormControl>
  );
};

export default Tabs;
