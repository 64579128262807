import React from "react";
import {
  ChipField,
  Datagrid,
  TextField,
  ListBase,
  FunctionField,
  RecordContextProvider,
  useRedirect,
  DatagridBody,
} from "react-admin";

import ToolBar from "./ToolBar/ToolBar";
import { Card, Avatar, TableHead, TableRow, TableCell } from "@mui/material";
import EastIcon from "@mui/icons-material/East";

const DatagridHeader = () => (
  <TableHead sx={{ backgroundColor: "#1E1E1E" }}>
    <TableRow>
      <TableCell align="left">Image</TableCell>
      <TableCell align="center">Brand</TableCell>
      <TableCell align="center">VIN</TableCell>
      <TableCell align="center">Mileage</TableCell>
      <TableCell align="center">Consumption</TableCell>
      <TableCell align="center">Category</TableCell>
      <TableCell align="center">SW Telemetry</TableCell>
      <TableCell align="center">Actions</TableCell>
    </TableRow>
  </TableHead>
);

const MyDatagridRow = ({ record, id, children }) => {
  const redirect = useRedirect();
  return (
    <RecordContextProvider value={record}>
      <TableRow
        hover={true}
        onClick={() => redirect(`/garage/${id}/show`)}
        sx={{ cursor: "pointer" }}
      >
        {React.Children.map(children, (field) => (
          <TableCell key={`${id}-${field.props.source}`}>{field}</TableCell>
        ))}
      </TableRow>
    </RecordContextProvider>
  );
};

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);

const GarageList = () => {
  return (
    <ListBase>
      <ToolBar />
      <Card
        sx={{
          border: "1px solid #1E1E1E",
          background: "transparent",
        }}
      >
        <Datagrid body={<MyDatagridBody />} header={<DatagridHeader />}>
          <FunctionField
            render={(record) => (
              <Avatar variant="circular" src={record.image[0].sm} />
            )}
          />
          <TextField source="brand" />
          <TextField source="VIN" />
          <TextField source="mileage" />
          <TextField source="consumption" />
          <ChipField
            source="category"
            sx={{
              backgroundColor: "#1E1E1E",
              color: "#fff",
              width: "80px",
              borderRadius: "8px",
            }}
          />
          <TextField source="telemetry" />
          <FunctionField render={() => <EastIcon color="primary" />} />
        </Datagrid>
      </Card>
    </ListBase>
  );
};

export default GarageList;
