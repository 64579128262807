import { Paper, Typography, Stack } from "@mui/material";
import Image from "mui-image";

const CircurtCard = ({ record }) => {
  return (
    <Paper sx={{ backgroundColor: "#fff", width: "100%", height: "349px" }}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={4}
        sx={{ p: "20.5px 14.5px" }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: "18px",
            color: "#000",
            textTransform: "uppercase",
            fontWeight: "bold",
            alignSelf: "flex-start",
          }}
        >
          {record?.location} <span style={{ color: "#8A9EAD" }}>CIRCUIT</span>
        </Typography>

        <Image
          duration={0}
          fit="contain"
          src={`/${record?.image[0].detail}`}
          alt=""
        />
      </Stack>
    </Paper>
  );
};

export default CircurtCard;
