import React from "react";
import {
  Datagrid,
  FunctionField,
  TextField,
  ListBase,
  RecordContextProvider,
  DatagridBody,
} from "react-admin";
import {
  Card,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Button,
  Stack,
  InputBase,
} from "@mui/material";

const DatagridHeader = () => (
  <TableHead sx={{ backgroundColor: "#1E1E1E" }}>
    <TableRow>
      <TableCell align="center">Lap</TableCell>
      <TableCell align="center">S1</TableCell>
      <TableCell align="center">S2</TableCell>
      <TableCell align="center">S3</TableCell>
      <TableCell align="center">Total</TableCell>
      <TableCell align="center">Speed</TableCell>
      <TableCell align="center">Fuel</TableCell>
      <TableCell align="center">Tyres</TableCell>
      <TableCell align="center">Lap Notes</TableCell>
    </TableRow>
  </TableHead>
);

const MyDatagridRow = ({ record, id, children, run }) => {
  return (
    <RecordContextProvider value={record}>
      {run === "running" ? (
        <TableRow
          sx={{
            cursor: "pointer",
            backgroundColor: record?.note ? "#1A1606" : "",
          }}
        >
          {React.Children.map(children, (field) => (
            <TableCell key={`${id}-${field.props.source}`}>{field}</TableCell>
          ))}
        </TableRow>
      ) : (
        record?.note || (
          <TableRow
            sx={{
              cursor: "pointer",
              backgroundColor: record?.note ? "#1A1606" : "",
            }}
          >
            {React.Children.map(children, (field) => (
              <TableCell key={`${id}-${field.props.source}`}>{field}</TableCell>
            ))}
          </TableRow>
        )
      )}
    </RecordContextProvider>
  );
};

const MyDatagridBody = ({ run, ...props }) => (
  <DatagridBody {...props} row={<MyDatagridRow run={run} />} />
);

const List = ({ run }) => {
  return (
    <ListBase resource="laps">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        my={3}
      >
        <Typography
          variant="body2"
          sx={{ fontSize: "18px", fontWeight: "bold" }}
        >
          Lap Details
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: "18px", fontWeight: "bold" }}
          >
            IDEAL LAPTIME <span style={{ color: "#0B9F00" }}>1.39,4</span>
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "18px", fontWeight: "bold" }}
          >
            REMAINING FUEL LAPS <span style={{ color: "#FF0000" }}>3</span>
          </Typography>
        </Stack>
      </Stack>
      <Card
        sx={{
          border: "1px solid #1E1E1E",
          background: "transparent",
        }}
      >
        <Datagrid
          body={<MyDatagridBody run={run} />}
          header={<DatagridHeader />}
        >
          <TextField source="lap" />

          <FunctionField
            render={(record) =>
              record.note ? (
                <InputBase
                  placeholder={record.s1?.value}
                  inputProps={{ sx: { textAlign: "center" } }}
                  sx={{
                    backgroundColor: "#fff",
                    color: "#000",
                    width: "81px",
                    height: "23px",
                    borderRadius: "8px",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                />
              ) : (
                <span style={{ color: record.s1?.color }}>
                  {record.s1?.value}
                </span>
              )
            }
          />
          <FunctionField
            render={(record) =>
              record.note ? (
                <InputBase
                  placeholder={record.s2?.value}
                  inputProps={{ sx: { textAlign: "center" } }}
                  sx={{
                    backgroundColor: "#fff",
                    width: "81px",
                    height: "23px",
                    borderRadius: "8px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#707070",
                  }}
                />
              ) : (
                <span style={{ color: record.s2?.color }}>
                  {record.s2?.value}
                </span>
              )
            }
          />

          <FunctionField
            render={(record) =>
              record.note ? (
                <InputBase
                  placeholder={record.s3?.value}
                  inputProps={{ sx: { textAlign: "center" } }}
                  sx={{
                    backgroundColor: "#fff",
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#707070",
                    width: "81px",
                    height: "23px",
                    borderRadius: "8px",
                  }}
                />
              ) : (
                <span style={{ color: record.s3?.color }}>
                  {record.s3?.value}
                </span>
              )
            }
          />

          <FunctionField
            render={(record) => (
              <span style={{ color: record.total?.color }}>
                {record.total?.value}
              </span>
            )}
          />

          <FunctionField
            render={(record) =>
              record.note ? (
                <InputBase
                  placeholder={record.speed}
                  inputProps={{ sx: { textAlign: "center" } }}
                  sx={{
                    backgroundColor: "#fff",
                    width: "81px",
                    height: "23px",
                    borderRadius: "8px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#707070",
                  }}
                />
              ) : (
                record.speed
              )
            }
          />

          <TextField source="fule" />

          <TextField source="types" />
          <FunctionField
            render={(record) =>
              record.note ? (
                <InputBase
                  placeholder={record.notes}
                  inputProps={{ sx: { textAlign: "center" } }}
                  sx={{
                    backgroundColor: "#fff",
                    width: "81px",
                    height: "23px",
                    borderRadius: "8px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#707070",
                  }}
                />
              ) : (
                record.notes
              )
            }
          />
        </Datagrid>

        <Button
          sx={{
            color: "#FEDA3B",
            fontSize: "14px",
            margin: "16px 0 16px 20px",
            textDecoration: "underline",
            textTransform: "capitalize",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          Add lap
        </Button>
      </Card>
      <Stack alignItems="center" justifyContent="center" sx={{ width: "100%" }}>
        {run === "running" && (
          <Button
            sx={{
              my: "12px",
              "&.MuiButton-contained": {
                width: "195px",
              },
            }}
            variant="contained"
          >
            End Run
          </Button>
        )}
      </Stack>
    </ListBase>
  );
};

export default List;
