import { useEffect, forwardRef } from "react";
import { Box } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

import { useListContext, useRedirect } from "react-admin";

import "./calendar.css";

const Calendar = forwardRef(({ SetCalendarApi }, ref) => {
  const { data } = useListContext();
  const redirect = useRedirect();

  const handleEvent = (e) => {
    redirect(`/events/${e?.event?.id}/show`);
  };

  useEffect(() => SetCalendarApi(ref.current?.getApi()));

  return (
    <Box sx={{ borderRadius: "8px", overflow: "hidden" }}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin]}
        displayEventTime={false}
        initialView="dayGridMonth"
        headerToolbar={false}
        contentHeight={300}
        events={data?.map((event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
        }))}
        eventClick={handleEvent}
        ref={ref}
      />
    </Box>
  );
});

export default Calendar;
