import { useState } from "react";
import { Stack, IconButton, Button } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useRedirect } from "react-admin";

import SetupModal from "./SetupModal";

const buttonStyle = {
  "&.MuiButton-contained": {
    width: "100px",
    height: "33px",
    fontSize: "12px",
    borderRadius: "17px",
    backgroundColor: "#fff",
  },
};

const ToolBar = ({ program, record, session }) => {
  const [open, setOpen] = useState(false);
  const [icon, setIcon] = useState(null);
  const [title, setTitle] = useState("");
  const redirect = useRedirect();

  const handleModal = (e) => {
    switch (e.currentTarget.id) {
      case "print":
        setIcon(<PrintOutlinedIcon fontSize="large" color="primary" />);
        setTitle("Print");
        break;
      case "headset":
        setIcon(<HeadsetMicOutlinedIcon fontSize="large" color="primary" />);
        setTitle("Technical remote support service");
        break;
      default:
        setIcon(null);
        setTitle("");
    }

    setOpen(true);
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" spacing={1} alignItems="end">
        <IconButton
          onClick={() => redirect(`/events/${record?.id}/show/session`)}
        >
          <KeyboardArrowLeft fontSize="large" />
        </IconButton>

        <Stack>
          <span style={{ color: "#FEDA3B", fontSize: "12px" }}>
            {`Calendar / ${record?.title} / ${program?.activity} / ${session?.name}`}
          </span>
          <span
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              margin: "10px 0",
            }}
          >
            {`Setup: #${session?.id} Detail`}
          </span>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <IconButton
          id="print"
          onClick={handleModal}
          sx={{
            backgroundColor: "#fff",
            color: "#000",
            "&:hover": { backgroundColor: "#fff", color: "#000" },
          }}
        >
          <PrintOutlinedIcon />
        </IconButton>

        <IconButton
          id="headset"
          onClick={handleModal}
          sx={{
            margin: "0 20.5px 0 12px",
            backgroundColor: "#fff",
            color: "#000",
            "&:hover": { backgroundColor: "#fff", color: "#000" },
          }}
        >
          <HeadsetMicOutlinedIcon />
        </IconButton>

        <Button
          id="edit"
          variant="contained"
          endIcon={<EditOutlinedIcon />}
          sx={buttonStyle}
        >
          Edit
        </Button>
      </Stack>
      {open && (
        <SetupModal open={open} setOpen={setOpen} icon={icon} title={title} />
      )}
    </Stack>
  );
};

export default ToolBar;
