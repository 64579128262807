import { Stack, Grid, Typography, InputBase, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import TyresCard from "./TyresCard";

export const TyresCardContent = ({
  title,
  unit,
  subTitle,
  textColor,
  isClickable,
}) => {
  const isClickableStyle = isClickable
    ? {
        cursor: "pointer",
        textDecoration: "underline",
        textDecorationColor: "#FEDA3B",
        textDecorationThickness: "3px",
        textDecorationStyle: "solid",
      }
    : {};
  return (
    <Stack direction="column" alignItems="flex-start" justifyContent="center">
      <Stack direction="row" alignItems="flex-end" justifyContent="center">
        <Typography
          variant="body2"
          sx={{
            fontSize: "34px",
            color: textColor,
            fontWeight: "bold",
            ...isClickableStyle,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: "12px",
            color: textColor,
            fontWeight: "bold",
            pb: "9px",
          }}
        >
          {unit}
        </Typography>
      </Stack>
      <Typography
        variant="body2"
        sx={{ fontSize: "10px", color: "#8A9EAD", fontWeight: "bold" }}
      >
        {subTitle}
      </Typography>
    </Stack>
  );
};

const TyresCardFooter = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{ border: "3px solid #EBEBEB" }}
      p={0.5}
    >
      <Typography
        variant="body2"
        sx={{ fontSize: "12px", color: "#000", fontWeight: "bold" }}
      >
        44°C
      </Typography>
      <Typography
        variant="body2"
        sx={{ fontSize: "12px", color: "#000", fontWeight: "bold" }}
      >
        54°C
      </Typography>
      <Typography
        variant="body2"
        sx={{ fontSize: "12px", color: "#000", fontWeight: "bold" }}
      >
        64°C
      </Typography>
    </Stack>
  );
};

const Card = ({ title }) => {
  return (
    <TyresCard
      chipColor={"#0A9F201A"}
      textColor={"#000"}
      bgcolor={"#fff"}
      icon={<CheckCircleIcon sx={{ color: "#0A9F20" }} fontSize="small" />}
      title={title}
      fireIcon={
        <LocalFireDepartmentIcon sx={{ color: "#FF0000" }} fontSize="small" />
      }
      moreIcon={<MoreVertIcon sx={{ color: "#000000" }} fontSize="small" />}
      cardContent={
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            my={2}
          >
            <TyresCardContent
              title={"1.8"}
              unit={"BAR"}
              subTitle={"START/CUT"}
              textColor={"#000"}
            />
            <TyresCardContent
              title={"1.9"}
              unit={"BAR"}
              subTitle={"RELIEF"}
              textColor={"#000"}
            />
          </Stack>
          <TyresCardFooter />
        </>
      }
    />
  );
};

const Tyres = ({ run }) => {
  return (
    <Grid container spacing={1} p={0.7}>
      <Grid item xs={6}>
        <TyresCard
          chipColor={"#0A9F201A"}
          textColor={"#000"}
          bgcolor={"#fff"}
          icon={<CheckCircleIcon sx={{ color: "#0A9F20" }} fontSize="small" />}
          title={"FL"}
          fireIcon={
            <LocalFireDepartmentIcon
              sx={{ color: "#FF0000" }}
              fontSize="small"
            />
          }
          moreIcon={<MoreVertIcon sx={{ color: "#000000" }} fontSize="small" />}
          cardContent={
            <>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
                my={2}
              >
                <TyresCardContent
                  title={"1.8"}
                  unit={"BAR"}
                  subTitle={"START/CUT"}
                  textColor={"#000"}
                />
                <TyresCardContent
                  title={"1.9"}
                  unit={"BAR"}
                  subTitle={"RELIEF"}
                  textColor={"#000"}
                />
              </Stack>
              <TyresCardFooter />
            </>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TyresCard
          chipColor={"#0A9F201A"}
          textColor={"#000"}
          bgcolor={"#fff"}
          icon={<CheckCircleIcon sx={{ color: "#0A9F20" }} fontSize="small" />}
          title={"FR"}
          fireIcon={
            <LocalFireDepartmentIcon
              sx={{ color: "#FF0000" }}
              fontSize="small"
            />
          }
          moreIcon={<MoreVertIcon sx={{ color: "#000000" }} fontSize="small" />}
          cardContent={
            <>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
                my={2}
              >
                <TyresCardContent
                  title={"1.8"}
                  unit={"BAR"}
                  subTitle={"START/CUT"}
                  textColor={"#000"}
                />
                <TyresCardContent
                  title={"1.9"}
                  unit={"BAR"}
                  subTitle={"RELIEF"}
                  textColor={"#000"}
                />
              </Stack>
              <TyresCardFooter />
            </>
          }
        />
      </Grid>
      <Grid item xs={6}>
        {run === "running" ? (
          <TyresCard
            chipColor={"#000"}
            textColor={"#fff"}
            bgcolor={"#000000"}
            title={"RL"}
            fireIcon={
              <LocalFireDepartmentIcon
                sx={{ color: "#FF0000" }}
                fontSize="small"
              />
            }
            moreIcon={<MoreVertIcon fontSize="small" />}
            cardContent={
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                  my={0.4}
                >
                  <div style={{ height: "50px" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "10px",
                        color: "#8A9EAD",
                        fontWeight: "bold",
                      }}
                    >
                      START/CUT
                    </Typography>
                    <TyresCardContent title={"1.8"} unit={"BAR"} />
                  </div>

                  <div style={{ height: "50px" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "10px",
                        color: "#8A9EAD",
                        fontWeight: "bold",
                        mb: 1,
                      }}
                    >
                      RELIEF
                    </Typography>
                    <InputBase
                      placeholder="insert"
                      sx={{
                        width: "63px",
                        height: "23px",
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                        color: "#707070",
                        fontSize: "11px",
                        pl: "5px",
                      }}
                    />
                  </div>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <div>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "10px",
                        color: "#8A9EAD",
                        fontWeight: "bold",
                      }}
                    >
                      INT
                    </Typography>
                    <InputBase
                      placeholder="insert"
                      sx={{
                        width: "42px",
                        height: "23px",
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                        color: "#707070",
                        fontSize: "11px",
                        pl: "5px",
                      }}
                    />
                  </div>
                  <div>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "10px",
                        color: "#8A9EAD",
                        fontWeight: "bold",
                      }}
                    >
                      MID
                    </Typography>
                    <InputBase
                      placeholder="insert"
                      sx={{
                        width: "42px",
                        height: "23px",
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                        color: "#707070",
                        fontSize: "11px",
                        pl: "5px",
                      }}
                    />
                  </div>
                  <div>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "10px",
                        color: "#8A9EAD",
                        fontWeight: "bold",
                      }}
                    >
                      EXT
                    </Typography>
                    <InputBase
                      placeholder="insert"
                      sx={{
                        width: "42px",
                        height: "23px",
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                        color: "#707070",
                        fontSize: "11px",
                        pl: "5px",
                      }}
                    />
                  </div>
                </Stack>

                <Button
                  variant="contained"
                  sx={{
                    "&.MuiButton-contained": {
                      borderRadius: "12px",
                      width: "138px",
                      height: "23px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "#000",
                      textTransform: "capitalize",
                      mt: "6px",
                    },
                  }}
                >
                  Complete
                </Button>
              </>
            }
          />
        ) : (
          <Card title={"RL"} />
        )}
      </Grid>
      <Grid item xs={6}>
        {run === "running" ? (
          <TyresCard
            chipColor={"#EBEBEB"}
            textColor={"#000"}
            bgcolor={"#fff"}
            title={"RR"}
            moreIcon={
              <MoreVertIcon sx={{ color: "#000000" }} fontSize="small" />
            }
            cardContent={
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={1}
                  my={2}
                >
                  <TyresCardContent
                    title={"1.8"}
                    unit={"BAR"}
                    subTitle={"START/CUT"}
                    textColor={"#000"}
                  />
                </Stack>
              </>
            }
          />
        ) : (
          <Card title={"RR"} />
        )}
      </Grid>
    </Grid>
  );
};

export default Tyres;
