import { useState } from "react";

import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import List from "./List";

const VechicleTabs = ({ garageData }) => {
  const [value, setValue] = useState(10);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <TabContext value={value}>
      <Box
        sx={{
          borderBottom: "1px solid #707070",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <TabList
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label={"GT3"} value={10} />
          <Tab label={"GT4"} value={20} />
        </TabList>
      </Box>

      <TabPanel value={10} sx={{ padding: "24px 0" }}>
        <List />
      </TabPanel>
      <TabPanel value={20} sx={{ padding: "24px 0" }}>
        <List />
      </TabPanel>
    </TabContext>
  );
};

export default VechicleTabs;
