import {
  ShowBase,
  TextField,
  useShowController,
  useRedirect,
} from "react-admin";
import { Stack, IconButton, Box, Avatar, Button } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

const MembersShow = (props) => {
  const { record } = useShowController();
  const redirect = useRedirect();
  const location = useLocation();

  return (
    <ShowBase {...props}>
      <Box>
        <Stack direction="row" spacing={1} alignItems="end">
          <IconButton
            onClick={() =>
              redirect(location.state.name === "attendees" ? -1 : "/members")
            }
          >
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>

          <Stack>
            <span
              style={{ color: "#FEDA3B", fontSize: "12px" }}
            >{`Team Members`}</span>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                margin: "10px 0",
              }}
            >
              Member Detail
            </span>
          </Stack>
        </Stack>

        <Box
          sx={{
            width: "100%",
            height: "200px",
            border: "1px solid #707070",
            margin: "30px 0",
            borderRadius: "8px",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Avatar
              src={record?.image[0]?.md}
              sx={{
                objectFit: "contain",
                width: "300px",
                height: "200px",
                borderRadius: "8px 0  0 8px",
              }}
              variant="square"
            />
            <Stack
              width="100%"
              p={8}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack>
                <div style={{ color: "#FEDA3B", fontSize: "12px" }}>
                  Member Name
                </div>
                <TextField
                  source="name"
                  sx={{ fontSize: "20px", fontWeight: "bold" }}
                />
              </Stack>

              <Stack>
                <div style={{ color: "#FEDA3B", fontSize: "12px" }}>
                  Member Role
                </div>
                <TextField
                  source="role"
                  sx={{ fontSize: "20px", fontWeight: "bold" }}
                />
              </Stack>

              <Stack>
                <div style={{ color: "#FEDA3B", fontSize: "12px" }}>
                  Telephone
                </div>
                <TextField
                  source="phone"
                  sx={{ fontSize: "20px", fontWeight: "bold" }}
                />
              </Stack>

              <Stack>
                <div style={{ color: "#FEDA3B", fontSize: "12px" }}>Email</div>
                <TextField
                  source="email"
                  sx={{ fontSize: "20px", fontWeight: "bold" }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "275px",
            border: "1px solid #707070",
            margin: "30px 0",
            borderRadius: "8px",
          }}
        >
          <Stack direction="column" p={3}>
            <h4 style={{ color: "#FEDA3B" }}>Bio</h4>
            <TextField source="bio" />
          </Stack>
        </Box>
        <Stack sx={{ width: "100%" }}>
          <Button
            target="_top"
            href="mailto:info@racingteamsapp.com?subject=Mail from Racing Team App Demo&body=Hi I am using Racing Team App"
            sx={{ margin: "50px auto" }}
            variant="contained"
          >
            Send Email
          </Button>
        </Stack>
      </Box>
    </ShowBase>
  );
};

export default MembersShow;
