import { useRedirect } from "react-admin";

import { Stack, IconButton, Paper, Typography, Button } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

const buttonStyle = {
  "&.MuiButton-contained": {
    width: "auto",
    height: "46px",
    fontSize: "14px",
    borderRadius: "23px",
    backgroundColor: "#fff",
  },
};

const ToolBar = ({ record, program }) => {
  const redirect = useRedirect();

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 3 }}
      >
        <Stack direction="row" spacing={1} alignItems="end">
          <IconButton onClick={() => redirect(`/events/${record?.id}/show`)}>
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>

          <Stack>
            <span style={{ color: "#FEDA3B", fontSize: "12px" }}>
              {`Attendance Calendar / ${record?.title}`}
            </span>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                margin: "10px 0",
              }}
            >
              {`Session: ${program?.activity}`}
            </span>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          {program?.status === "Completed" && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Button
                onClick={() =>
                  redirect(
                    `/events/${record?.id}/show/session/telemetry-notes/${program?.id}`
                  )
                }
                variant="contained"
                startIcon={<DescriptionOutlinedIcon />}
                sx={buttonStyle}
              >
                Telemetry Notes
              </Button>
              <Button
                onClick={() =>
                  redirect(
                    `/events/${record?.id}/show/session/driver-report/${program?.id}`
                  )
                }
                sx={buttonStyle}
                variant="contained"
                startIcon={<RecordVoiceOverIcon />}
              >
                Driver Feedback Report
              </Button>
            </Stack>
          )}

          <Paper
            sx={{
              width: "206px",
              height: "19px",
              borderRadius: "25px",
              py: "15px",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Typography variant="body2" sx={{ fontSize: "14px" }}>
                STATUS:
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: program?.status === "Planned" ? "#FEDA3B" : "#0A9F20",
                }}
              >
                {program?.status === "Planned" ? "RUNNING" : "COMPLETED"}
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </>
  );
};

export default ToolBar;
