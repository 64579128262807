import { useState, useEffect } from "react";
import { FilterForm, useListContext, SearchInput } from "react-admin";
import { Stack, Button, Paper } from "@mui/material";

const activeStyle = {
  backgroundColor: "#FEDA3B",
  color: "#000",
  "&:hover": {
    backgroundColor: "#FEDA3B",
    color: "#000",
  },
};

const ToolBar = () => {
  const [value, setValue] = useState("");
  const { setFilters } = useListContext();

  const handleFilter = (event) => {
    setValue(event.target?.id);
    if (event.target?.id === "All") setFilters([], {});
    else {
      setFilters({ role: event.target?.id });
    }
  };

  useEffect(() => {
    setFilters([], {});
    setValue("All");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack mb={4}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <h2 style={{ margin: "0" }}>Team Members</h2>
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Paper
            sx={{
              width: "auto",
              height: "30px",
              padding: "5px",
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={1}
              direction="row"
            >
              <Button
                variant="text"
                id="All"
                onClick={handleFilter}
                sx={value === "All" ? activeStyle : {}}
              >
                ALL
              </Button>
              <Button
                variant="text"
                id="Engineer"
                onClick={handleFilter}
                sx={value === "Engineer" ? activeStyle : {}}
              >
                ENGINEER
              </Button>
              <Button
                variant="text"
                id="Driver"
                onClick={handleFilter}
                sx={value === "Driver" ? activeStyle : {}}
              >
                DRIVER
              </Button>
              <Button
                variant="text"
                id="Mechanic"
                onClick={handleFilter}
                sx={value === "Mechanic" ? activeStyle : {}}
              >
                MECHANIC
              </Button>
            </Stack>
          </Paper>
          <FilterForm
            sx={{
              alignItems: "center",
              "& .RaFilterFormInput-spacer": {
                display: "none",
              },
            }}
            filters={[
              <SearchInput
                InputLabelProps={{ shrink: true }}
                size="small"
                color="secondary"
                variant="filled"
                source="q"
                alwaysOn
                placeholder="Search"
                sx={{
                  "&.MuiTextField-root": {
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    border: "1px solid #fff",
                    width: "200px",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#BDC8D1",
                  },
                  input: {
                    color: "#000",
                    "&:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 100px #fff inset",
                      WebkitTextFillColor: "#000",
                    },
                  },
                }}
              />,
            ]}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ToolBar;
