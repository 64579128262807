import { useGetOne } from "react-admin";
import { useParams } from "react-router-dom";

import { Box, Stack, Button } from "@mui/material";

import ToolBar from "./ToolBar";
import Reference from "./Reference";

const TyresPressure = () => {
  const { id: eventId } = useParams();
  const { sessionId } = useParams();
  const { data: record } = useGetOne("events", {
    id: eventId,
  });
  const { data: program } = useGetOne("program", {
    id: localStorage.getItem("progrmaId"),
  });

  const { data: session } = useGetOne("planned", {
    id: sessionId,
  });
  return (
    <>
      <ToolBar program={program} record={record} session={session} />
      <Box sx={{ ml: "63px" }}>
        <Reference
          label="REFERENCE SET#2"
          titleTemperature="Measured Track Temperature"
          temperature="23°C"
          name="Max Measured Pressure @Hot"
          valueOne="1.80"
          ValueTwo="1.82"
          ValueThree="1.64"
          ValueFour="1.69"
        />
        <Reference
          label="REFERENCE SET#2"
          titleTemperature="Measured Tyres Temperature"
          temperature="23°C"
          name="Measured Pressure @Cold"
          valueOne="1.80"
          ValueTwo="1.82"
          ValueThree="1.64"
          ValueFour="1.69"
        />
        <Reference
          label="SET#3 TO BE USED"
          titleTemperature="Estimated Track Temperature"
          temperature="23°C"
          name="Target Pressure @Hot"
          valueOne="1.80"
          ValueTwo="1.82"
          ValueThree="1.64"
          ValueFour="1.69"
        />
        <Reference
          label="MEASURED TIRES TEMPERATURE"
          titleTemperature="Measured Tires Temperature"
          temperature="23°C"
          name="Requested Pressure @Cold"
          valueOne="1.80"
          ValueTwo="1.82"
          ValueThree="1.64"
          ValueFour="1.69"
        />
      </Box>
      <Stack alignItems="center" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          sx={{
            my: "40px",
            "&.MuiButton-contained": {
              width: "195px",
            },
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </>
  );
};

export default TyresPressure;
