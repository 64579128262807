import { Paper, Typography, Box } from "@mui/material";

const DetailCard = ({ title, cardWidth, cardHeight, content }) => {
  return (
    <Paper sx={{ maxWidth: cardWidth, width: "100%", height: cardHeight }}>
      <Typography
        color="primary"
        variant="body2"
        sx={{
          fontSize: "16px",
          fontWeight: "bold",
          m: "12px 12px 0 12px",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          width: cardWidth,
        }}
      >
        {content}
      </Box>
    </Paper>
  );
};

export default DetailCard;
