import { useState } from "react";

import { Button, Grid, TextField, FormControl, MenuItem } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const Tabs = () => {
  const [date, setDate] = useState(null);
  return (
    <FormControl fullWidth>
      <Grid container spacing={2} py={2}>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth label="Mileage" variant="outlined" />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth select label="Status" variant="outlined">
              <MenuItem value="New">New</MenuItem>
              <MenuItem value="Revised">Revised</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth label="Track" variant="outlined" />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth select label="Availability" variant="outlined">
              <MenuItem value="to be ordered">yes, to be mounted</MenuItem>
              <MenuItem value="to be mounted">no, to be ordered</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth label="Price" variant="outlined" />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth variant="outlined" />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth select label="Notification" variant="outlined">
              <MenuItem value="100">Notify me 100 km before</MenuItem>
              <MenuItem value="200">Notify me 200 km before</MenuItem>
              <MenuItem value="300">Notify me 300 km before</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <Button variant="contained" sx={{ margin: "20px auto" }}>
        Save
      </Button>
    </FormControl>
  );
};

export default Tabs;
