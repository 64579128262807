import { useState } from "react";
import { useGetOne, useRedirect } from "react-admin";
import { Stack, IconButton, Avatar, Box, Tab } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { engine } from "./Tabs/tabs.config";
import FormTab from "./Tabs/EngineTabs";

const EngineSystem = () => {
  const [value, setValue] = useState(1);
  const redirect = useRedirect();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data } = useGetOne("garage", { id: localStorage.getItem("carId") });
  return (
    <Box>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 2 }}
        alignItems="center"
        mb={4}
      >
        <IconButton
          onClick={() => redirect(`/garage/${data?.id}/show/mileage`)}
        >
          <KeyboardArrowLeft fontSize="large" />
        </IconButton>
        <Avatar
          src={`/${data?.image[0]?.sm}`}
          sx={{ width: "60px", height: "60px" }}
        />
        <Stack>
          <span style={{ color: "#FEDA3B", fontSize: "12px" }}>
            {`Garage / ${data?.brand}`}
          </span>
          <span
            style={{
              fontSize: "25px",
              fontWeight: "bold",
              margin: "10px 0",
            }}
          >
            Engine System
          </span>
        </Stack>
      </Stack>
      <TabContext value={value}>
        <Box
          sx={{
            border: "1px solid #707070",
            marginBottom: "20px",
          }}
        >
          <TabList
            value={value}
            onChange={handleChange}
            scrollButtons="auto"
            variant="scrollable"
          >
            {engine.map((item) => (
              <Tab key={item.id} label={item.title} value={item.id} />
            ))}
          </TabList>
        </Box>
        {engine.map((item) => (
          <TabPanel value={item.id} sx={{ padding: "24px 0" }}>
            <span>{item.title}</span>
            <FormTab />
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default EngineSystem;
