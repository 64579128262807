import { Stack, Paper, Modal, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 3,
  width: "750px",
  height: "150px",
  borderRadius: "8px",
};

const SetupModal = ({ open, setOpen, icon, title }) => {
  const handleClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          spacing={3}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              color: "#fff",
              backgroundColor: "#000",
              alignSelf: "flex-end",
              fontSize: "20px",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          {icon}
          <Typography variant="body1">
            {title} functionality will be developed in the complete version of
            the app
          </Typography>
        </Stack>
      </Paper>
    </Modal>
  );
};

export default SetupModal;
