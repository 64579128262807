import { Stack, Grid, Typography, Divider } from "@mui/material";

const Reference = ({
  label,
  titleTemperature,
  temperature,
  name,
  valueOne,
  ValueTwo,
  ValueThree,
  ValueFour,
}) => {
  const tyreemperature = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ p: "17px 20px", border: "1px solid #fff" }}
    >
      <Typography variant="body" sx={{ fontSize: "14px" }}>
        {titleTemperature}
      </Typography>
      <Typography
        variant="body"
        color="primary"
        sx={{ fontSize: "14px", fontWeight: "bold" }}
      >
        {temperature}
      </Typography>
    </Stack>
  );

  const TyrePressure = ({ value }) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          p: "17px 20px",
          border:
            label === "MEASURED TIRES TEMPERATURE"
              ? "1px solid #262626"
              : "1px solid #fff",
          backgroundColor:
            label === "MEASURED TIRES TEMPERATURE" ? "#262626" : "",
        }}
      >
        <Typography variant="body" sx={{ fontSize: "14px" }}>
          {name}
        </Typography>
        <Typography
          variant="body"
          color={label === "MEASURED TIRES TEMPERATURE" ? "" : "primary"}
          sx={{ fontSize: "14px", fontWeight: "bold" }}
        >
          {value}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack>
      <Typography
        variant="body"
        color="primary"
        sx={{ fontSize: "16px", fontWeight: "bold", mb: 2 }}
      >
        {label}
      </Typography>
      <Divider sx={{ borderBottomColor: "#fff", mb: "23px" }} />
      <Grid container sx={{ mb: "6px" }} columnSpacing={2} rowSpacing={1.5}>
        <Grid item xs={5}>
          {tyreemperature}
        </Grid>
        <Grid item xs={3.5}>
          <TyrePressure value={valueOne} />
        </Grid>
        <Grid item xs={3.5}>
          <TyrePressure value={ValueTwo} />
        </Grid>

        <Grid item xs={5}></Grid>
        <Grid item xs={3.5}>
          <TyrePressure value={ValueThree} />
        </Grid>
        <Grid item xs={3.5}>
          <TyrePressure value={ValueFour} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Reference;
