import React from "react";
import {
  Datagrid,
  FunctionField,
  TextField,
  ListBase,
  ChipField,
  useRedirect,
  RecordContextProvider,
  DatagridBody,
} from "react-admin";
import ToolBar from "./ToolBar/ToolBar";
import {
  LinearProgress,
  Card,
  TableHead,
  TableRow,
  TableCell,
  Avatar,
  Stack,
  Typography,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";

const DatagridHeader = () => (
  <TableHead sx={{ backgroundColor: "#1E1E1E" }}>
    <TableRow>
      <TableCell align="left">Image</TableCell>
      <TableCell align="center">Set Id</TableCell>
      <TableCell align="center">Brand</TableCell>
      <TableCell align="center">Usage</TableCell>
      <TableCell align="left">Mileage</TableCell>
      <TableCell align="center">Actions</TableCell>
    </TableRow>
  </TableHead>
);

const MyDatagridRow = ({ record, id, children }) => {
  const redirect = useRedirect();
  return (
    <RecordContextProvider value={record}>
      <TableRow
        hover={true}
        onClick={() =>
          redirect(
            `/garage/${localStorage.getItem("carId")}/show/tyres/${id}/show`
          )
        }
        sx={{ cursor: "pointer" }}
      >
        {React.Children.map(children, (field) => (
          <TableCell key={`${id}-${field.props.source}`}>{field}</TableCell>
        ))}
      </TableRow>
    </RecordContextProvider>
  );
};

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);

const TyresList = () => {
  return (
    <ListBase resource="tyres">
      <ToolBar />
      <Card
        sx={{
          border: "1px solid #1E1E1E",
          background: "transparent",
        }}
      >
        <Datagrid body={<MyDatagridBody />} header={<DatagridHeader />}>
          <FunctionField
            render={(record) => (
              <Avatar variant="circular" src={record.image} />
            )}
          />
          <FunctionField
            render={(record) => {
              return `#${record.id}`;
            }}
          />
          <TextField source="name" />

          <ChipField
            source="Usage"
            sx={{
              backgroundColor: "#1E1E1E",
              color: "#fff",
              width: "70px",
              borderRadius: "8px",
            }}
          />

          <FunctionField
            render={(record) => {
              return (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={1}
                >
                  <Typography variant="body2">
                    {`${record.mileage} km`}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={record.mileage}
                    sx={{
                      "&.MuiLinearProgress-root": {
                        borderRadius: "17px",
                        height: "4px",
                        backgroundColor: "#121212",
                        width: "80px",
                      },
                      ".MuiLinearProgress-bar1Determinate": {
                        backgroundColor: "#EBEBEB",
                        borderRadius: "17px",
                        height: "4px",
                        width: "80px",
                      },
                    }}
                  />
                </Stack>
              );
            }}
          />

          <FunctionField render={() => <EastIcon color="primary" />} />
        </Datagrid>
      </Card>
    </ListBase>
  );
};

export default TyresList;
