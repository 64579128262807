import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

// $FIREBASE_TOKEN= "1//09ouc-LuBvYvxCgYIARAAGAkSNwF-L9IrZ7z5XxOPqEydiT14Dt5J0yBSR2BNPgsBDGLk1q2xEk49lKcofC22nfAIrGOtWaHwIwk"

const firebaseConfig = {
  apiKey: "AIzaSyAryKPLZir8Gw3nuQmw6Run-rsI_aOvBw4",
  authDomain: "racing-teams.firebaseapp.com",
  projectId: "racing-teams",
  storageBucket: "racing-teams.appspot.com",
  messagingSenderId: "982715894258",
  appId: "1:982715894258:web:ecf578ca5170578640c36b",
  measurementId: "G-SB3XCK6V4S",
};
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore();

export { firebaseConfig, db, firebaseApp };
