import { useRedirect } from "react-admin";
import {
  Stack,
  Paper,
  Modal,
  Typography,
  Button,
  InputBase,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  paddingTop: "44.5px",
  width: "372.13px",
  height: "400.5px",
  borderRadius: "0",
  backgroundColor: "#000",
  boxShadow: "0px 15px 25px #4B546566",
  opacity: 1,
};
const SessionModal = ({ open, setOpen, record }) => {
  const redirect = useRedirect();
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Stack direction="column" alignItems="center">
          <Typography
            variant="body2"
            color="primary"
            sx={{ fontWeight: "bold", fontSize: "29px" }}
          >
            Run Starting
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", fontSize: "29px" }}
          >
            Qualification
          </Typography>

          <Typography variant="body2" sx={{ my: "33px", fontSize: "12px" }}>
            In case of need, please enter fuel refill amount
          </Typography>
          <Paper
            sx={{
              width: "187px",
              borderRadius: "0",
              height: "28px",
              p: "5px 8px",
            }}
          >
            <Typography variant="body2" sx={{ fontSize: "11px" }}>
              Remaining Fuel
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "11px" }}>
              20 Kg
            </Typography>
          </Paper>
          <InputBase
            placeholder="Fuel Refill"
            sx={{
              marginTop: "11px",
              width: "203px",
              borderRadius: "0",
              border: "1px solid #fff",
              height: "38px",
              p: "5px 8px",
              fontSize: "11px",
            }}
          />
          <Button
            onClick={() => {
              redirect(`/events/${record?.id}/show/session/run/${3}`);
              localStorage.setItem("run", "running");
            }}
            variant="contained"
            sx={{
              marginTop: "89px",
              "&.MuiButton-contained": {
                backgroundColor: "#fff",
                width: "203px",
                height: "38px",
                borderRadius: "19px",
                fontSize: "12px",
              },
            }}
          >
            Start Run
          </Button>
        </Stack>
      </Paper>
    </Modal>
  );
};

export default SessionModal;
