import { useState } from "react";
import {
  Avatar,
  IconButton,
  MenuItem,
  ListItemIcon,
  Menu as MMenu,
  Typography
} from "@mui/material";
import { useLogout, useGetOne } from "react-admin";
import { Logout as LogoutIcon, VpnKeyOff as VpnKeyOffIcon } from "@mui/icons-material";

export const AvatarMenu = () => {
  const { data } = useGetOne("members", {
    id: localStorage.getItem("memberId"),
  });
  const logout = useLogout();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = event => anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  return (
    <>
      <IconButton onClick={handleMenu} >
        <Avatar
          alt=""
          src={`/${data?.image[0]?.sm}`}
          sx={{ width: "47px", height: "47px", padding: 0 }}
        />
        <MMenu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleMenu}
          onClick={handleMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: -1,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                margin: 0,
                padding: 0,
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

        >
          <MenuItem onClick={logout} >
            <ListItemIcon >
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
          <MenuItem onClick={()=> logout({removeAccessCode: true})} >
            <ListItemIcon >
              <VpnKeyOffIcon fontSize="small" />
            </ListItemIcon>
            Revoke Demo Code
          </MenuItem>
        </MMenu>
      </IconButton>
      <Typography variant="body2" align="center" fontSize={"12px"} sx={{lineHeight:1, my:1}}>
        {localStorage.getItem("role")}
      </Typography>
    </>
  )
}